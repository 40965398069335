import React, { Component } from "react";
import ReactMoment from "react-moment";

class PostSidebar extends Component {
	showSidebar = () => {
		const {
			post: { post },
		} = this.props;

		return (
			<div>
				<div className="sidebar inline-block bg-white relative md:absolute pin-t pin-b pin-r p-6">
					<div className="flex flex-col h-full">
						<header className="profile-info flex items-center">
							<a
								target="_blank"
								rel="noopener noreferrer"
								title="View on Instagram.com"
								href={post.permalink}
							>
								<div className="profile-picture flex-shrink-0 flex items-center mr-3">
									<img
										className="w-12 h-12 md:w-10 md:h-10 rounded-full border border-black p-2px"
										src={require("../../../../assets/images/logos/profile_pic.jpg")}
										alt={post.username}
									/>
								</div>
							</a>
							<a
								target="_blank"
								rel="noopener noreferrer"
								className="username flex text-lg md:text-base font-semibold text-black no-underline"
								title="View on Instagram.com"
								href={post.permalink}
							>
								{post.username}
							</a>
						</header>
						<div className="border-t-2 border-grey-lightest mt-6 mb-4" />
						{/* Caption */}
						{post.caption && (
							<a
								target="_blank"
								rel="noopener noreferrer"
								className="caption flex text-lg md:text-base text-black leading-0 no-underline mb-6"
								title="Open link on instagram.com"
								href={post.permalink}
							>
								<div className="w-full text-lg md:text-base leading-normal">
									<div className="float-left font-semibold mr-2">
										{post.username}
									</div>
									<div className="font-medium">{post.caption}</div>
								</div>
							</a>
						)}
						{/* Date */}
						<a
							target="_blank"
							rel="noopener noreferrer"
							className="date flex text-xs font-semibold text-black opaque tracking-wide uppercase no-underline mb-6"
							title="Open link on instagram.com"
							href={post.permalink}
						>
							<ReactMoment fromNow>{post.timestamp}</ReactMoment>
						</a>

						{/* Bottom link */}
						<div className="flex items-center justify-center mt-auto">
							<a
								target="_blank"
								rel="noopener noreferrer"
								title="Open link on instagram.com"
								className="inline-flex items-center text-lg md:text-lg md:text-base text-black font-semibold no-underline"
								href={post.permalink}
							>
								<span className="underline-indigo">
									Open link on instagram.com
								</span>
								<span className="flex pl-2">
									<svg
										className="h-4 w-4"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	};
	render() {
		return this.showSidebar();
	}
}

export default PostSidebar;
