import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import Navigation from "../../components/Navigation";
import VideoBackground from "../../components/VideoBackground";
import PageNotFound from "../../components/PageNotFound";
import Footer from "../../components/Footer";

class ErrorPageLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgLoaded: false
    };
  }

  addMetaTags = () => {
    return (
      <MetaTags>
        <title>Bobby Patterson | Page Not Found</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="This page doesn't exist. Maybe it used to but it doesn't anymore, obviously. Sorry about that."
        />
        <meta
          name="keywords"
          content="Bobby Patterson, Full Stack Developer, Front-end Developer, UI designer, Tempe, Phoenix, Scottsdale"
        />
        <meta property="og:url" content="https://bobbypatterson.me/404" />
        <meta property="og:title" content="Bobby Patterson | Page Not Found" />
        <meta
          property="og:site_name"
          content="Bobby Patterson | Page Not Found"
        />
        <meta
          property="og:description"
          content="This page doesn't exist. Maybe it used to but it doesn't anymore, obviously. Sorry about that."
        />
        <meta
          property="og:image"
          content="https://bobbypatterson.me/images/bg-bedroom.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@bobbypatterson" />
        <meta name="twitter:creator" content="@bobbypatterson" />
        <meta name="twitter:title" content="Bobby Patterson | Page Not Found" />
        <meta
          name="twitter:description"
          content="This page doesn't exist. Maybe it used to but it doesn't anymore, obviously. Sorry about that."
        />
        <meta
          name="twitter:image"
          content="https://bobbypatterson.me/images/bg-bedroom.jpg"
        />
        <link rel="canonical" href="https://bobbypatterson.me/404" />
      </MetaTags>
    );
  };

  bgHeight = () => {
    this.setState({
      bgLoaded: true,
      bgHeight: document.querySelector(".bg-image").clientHeight
    });
  };

  componentDidMount() {
    this.bgHeight();
  }

  render() {
    const { bgLoaded, bgHeight } = this.state;
    const { isMobile } = this.props;

    return (
      <div className="Error">
        <main>
          {this.addMetaTags()}

          {/* Error Header */}
          <header className="flex flex-col items-center justify-center w-full min-h-screen relative">
            {/* Navigation */}
            <Navigation
              classFontColor="text-white"
              classScrolledBackgroundColor="bg-intro-blue"
              {...this.props}
            />

            <section className="flex-1 flex items-center justify-center flex-col w-full mt-24 z-10">
              <div className="container w-full mx-auto">
                <PageNotFound />
              </div>
            </section>

            {/* Background image */}
            <div className="bg-image background block fixed pin-t pin-l min-w-full min-h-full performance">
              <div className="bg-intro-blue fixed pin-t pin-l bg-right w-full h-full performance" />
              {bgLoaded ? (
                <div>
                  <div className="bg-fonze fixed pin-t pin-l bg-right w-full h-full performance" />
                  <VideoBackground bgHeight={bgHeight} isMobile={isMobile} />
                </div>
              ) : null}
            </div>

            {/* Footer section */}
            <Footer classFontColor="text-white" />
          </header>
        </main>
      </div>
    );
  }
}

const propTypes = {
  isMobile: PropTypes.bool,
  isHidden: PropTypes.bool
};

ErrorPageLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  isMobile: state.environmentReducer.isMobile,
  isHidden: state.navigationReducer.isHidden
});

export default connect(mapStateToProps)(ErrorPageLayout);
