import React, { Component } from "react";
import { Link } from "react-router-dom";
import SocialButtons from "./SocialButtons";

class ProfessionalProfile extends Component {
	render() {
		return (
			<div>
				<div className="container w-full max-w-screen-xl flex items-center justify-center text-white mx-auto pt-2 md:pt-0 px-6">
					<div className="flex flex-col-reverse md:flex-row items-center w-full flex-reverse">
						<div className="flex items-center justify-center w-1/2 md:w-128 md:pl-6">
							<SocialButtons />
						</div>
						<div className="flex flex-col mt-24 md:my-0 md:pl-6">
							<p className="text-lg lg:text-xl font-medium leading-normal pt-0 pb-6 md:pr-16">
								<span className="text-sm lg:text-base font-bold uppercase tracking-wide mr-1">
									Hello!
								</span>{" "}
								I'm{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									title="Bobby Patterson"
									className="text-white font-semibold underline-white"
									href="https://www.linkedin.com/in/hello-bobby/"
								>
									Bobby Patterson
								</a>
								, a multidisciplinary developer and UI designer living in Tempe,
								Arizona. I have experience in all aspects of the development
								life-cycle, but I specialize in front-end development. I am
								experienced in an agency setting and I've built sites for
								smaller businesses. I have been fortunate in my career to have
								worked on projects for{" "}
								<Link
									title="Canon"
									className="text-white font-semibold underline-white"
									to="/work-projects/canon"
								>
									Canon
								</Link>
								,{" "}
								<Link
									title="Toshiba"
									className="text-white font-semibold underline-white"
									to="/work-projects/toshiba"
								>
									Toshiba
								</Link>
								, and{" "}
								<Link
									title="Kaiser Permanente"
									className="text-white font-semibold underline-white"
									to="/work-projects/kaiser-permanente"
								>
									Kaiser Permanente
								</Link>
								. I really enjoy finding solutions to complicated problems.
							</p>
							<div className="line-seperator w-10 mb-6 bg-white opacity-50" />
							<p className="text-lg lg:text-xl font-medium leading-normal mb-10 md:mb-0 md:pr-16">
								Email me at{" "}
								<a
									className="text-white font-semibold underline-white"
									href="mailto:&#098;&#111;&#098;&#098;&#121;&#112;&#097;&#116;&#116;&#101;&#114;&#115;&#111;&#110;&#052;&#048;&#052;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
								>
									&#098;&#111;&#098;&#098;&#121;&#112;&#097;&#116;&#116;&#101;&#114;&#115;&#111;&#110;&#052;&#048;&#052;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
								</a>
								<br />
								Message me on{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className="text-white font-semibold underline-white"
									href="https://www.linkedin.com/in/hello-bobby/"
								>
									LinkedIn
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default ProfessionalProfile;
