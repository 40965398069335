import * as types from "../constants/ActionTypes";

export const initEnvironment = () => {
	return dispatch => {
		const w = window,
			d = document,
			e = d.documentElement,
			g = d.getElementsByTagName("body")[0],
			windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; // Window width

		const dimensions = {
			width: window.innerWidth,
			height: window.innerHeight
		};

		const isMobile =
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			) || windowWidth < 767;

		dispatch(changeIsMobile(isMobile));
		dispatch(changeWidthAndHeight(dimensions));

		window.onresize = () => {
			dispatch(changeWidthAndHeight(dimensions));
		};
	};
};

export const changeIsMobile = isMobile => ({
	type: types.IS_MOBILE,
	payload: isMobile
});

export const changeWidthAndHeight = dimensions => ({
	type: types.CHANGE_WIDTH_AND_HEIGHT,
	payload: dimensions
});

export const loadState = () => {
	try {
		const serializedState = localStorage.getItem("state");
		if (serializedState === null) {
			return undefined;
		}
		return JSON.parse(serializedState);
	} catch (error) {
		return undefined;
	}
};

export const saveState = state => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem("state", serializedState);
	} catch (error) {
		// Ignore errors
	}
};

export const enableDarkMode = actionType => {
	return dispatch => {
		// Enable / Disable
		const enableDarkMode = payload => {
			dispatch({
				type: actionType,
				payload
			});
		};

		switch (actionType) {
			case types.DARK_MODE_ENABLED:
				if (!document.body.classList.contains("dark-mode")) {
					document.body.classList.add("dark-mode");
				}
				enableDarkMode(true);
				break;

			case types.DARK_MODE_DISABLED:
				if (document.body.classList.contains("dark-mode")) {
					document.body.classList.remove("dark-mode");
				}
				enableDarkMode(false);
				break;

			default:
				return null;
		}
	};
};
