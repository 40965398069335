import React, { Component } from "react";

class IEMessage extends Component {
  render() {
    return (
      <div>
        <div className="flex items-center justify-center mx-auto leading-normal">
          <div className="flex flex-col w-full text-center">
            <p className="text-2xl text-white font-medium -mt-8 mb-8">
              This browser is obsolete and supporting it properly would require
              a bit
              <br /> more work than I have time for at the moment. My apologies.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default IEMessage;
