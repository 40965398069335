import * as types from "../constants/ActionTypes";

const initialState = {
  darkModeEnabled: false,
  isMobile: false,
  width: null,
  height: null
};

export default function environmentReducer(state = initialState, action) {
  switch (action.type) {
    case types.DARK_MODE_ENABLED:
      return {
        ...state,
        darkModeEnabled: action.payload
      };

    case types.DARK_MODE_DISABLED:
      return {
        ...state,
        darkModeEnabled: action.payload
      };

    case types.IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload
      };

    case types.CHANGE_WIDTH_AND_HEIGHT:
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height
      };

    default:
      return state;
  }
}
