import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PostMedia from "./PostMedia";
import PostButtonControls from "./PostButtonControls";
import PostSidebar from "./PostSidebar";
import PostButtonControlsLoadingSkeleton from "./PostButtonControlsLoadingSkeleton";
import LoadingSkeleton from "./LoadingSkeleton";
import ErrorSkeleton from "./ErrorSkeleton";

class ContentModal extends Component {
	showContentModal = () => {
		const { isFetchingPost, error, post } = this.props;

		return (
			<div className="modal-container flex items-center justify-center h-full bg-modal z-50">
				{/* Post media */}
				<div>
					{isFetchingPost ? (
						<LoadingSkeleton {...this.props} />
					) : post ? (
						<article className="modal m-auto w-full h-full relative pointer-events-auto shadow-lg rounded-lg overflow-hidden z-50">
							{/* Media data */}
							<PostMedia {...this.props} />
							{/* Sidebar data */}
							<PostSidebar {...this.props} />
						</article>
					) : (
						error && <ErrorSkeleton {...this.props} />
					)}
				</div>
				{/* Buttons */}
				<div>
					{isFetchingPost ? (
						<div>
							{/* Button controls skeleton */}
							<PostButtonControlsLoadingSkeleton {...this.props} />
						</div>
					) : post && !error ? (
						<div>
							{/* Button controls */}
							<PostButtonControls {...this.props} />
						</div>
					) : (
						<PostButtonControlsLoadingSkeleton {...this.props} />
					)}
				</div>
			</div>
		);
	};

	render() {
		return this.props.modalOpen && this.showContentModal();
	}
}

const propTypes = {
	isFetchingPost: PropTypes.bool,
	modalOpen: PropTypes.bool,
	isHidden: PropTypes.bool,
	isMobile: PropTypes.bool,
	isScrolled: PropTypes.bool,
	loading: PropTypes.bool,
};

ContentModal.propTypes = propTypes;

const mapStateToProps = (state) => ({
	isFetchingPost: state.instagramModalReducer.loading,
	modalOpen: state.instagramModalReducer.modalOpen,
	isHidden: state.navigationReducer.isHidden,
	isMobile: state.environmentReducer.isMobile,
	isScrolled: state.navigationReducer.isScrolled,
	loading: state.instagramDataReducer.loading,
	error: state.instagramDataReducer.error,
	posts: state.instagramDataReducer.posts,
	post: state.instagramModalReducer.post,
	dimensions: state.instagramModalReducer.dimensions,
});

export default connect(mapStateToProps)(ContentModal);
