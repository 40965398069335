export const IS_SCROLLING = "IS_SCROLLING";
export const SCROLLING_DOWN = "SCROLLING_DOWN";
export const SCROLLING_UP = "SCROLLING_UP";

export const DARK_MODE_ENABLED = "DARK_MODE_ENABLED";
export const DARK_MODE_DISABLED = "DARK_MODE_DISABLED";

// Loading initial instagram feed data
export const GET_INSTAGRAM_DATA_BEGIN = "GET_INSTAGRAM_DATA_BEGIN";
export const GET_INSTAGRAM_DATA_SUCCESS = "GET_INSTAGRAM_DATA_SUCCESS";
export const GET_INSTAGRAM_DATA_FAILURE = "GET_INSTAGRAM_DATA_FAILURE";

export const GET_INSTAGRAM_POST_DIMENSIONS = "GET_INSTAGRAM_POST_DIMENSIONS";
export const GET_INSTAGRAM_PAGE_DIMENSIONS = "GET_INSTAGRAM_PAGE_DIMENSIONS";

// Loading post data as a modal
export const GET_INSTAGRAM_POST_DATA_BEGIN = "GET_INSTAGRAM_POST_DATA_BEGIN";
export const GET_INSTAGRAM_POST_DATA_SUCCESS =
	"GET_INSTAGRAM_POST_DATA_SUCCESS";
export const GET_INSTAGRAM_POST_DATA_FAILURE =
	"GET_INSTAGRAM_POST_DATA_FAILURE";

// Loading post data as a page
export const GET_INSTAGRAM_PAGE_DATA_BEGIN = "GET_INSTAGRAM_PAGE_DATA_BEGIN";
export const GET_INSTAGRAM_PAGE_DATA_SUCCESS =
	"GET_INSTAGRAM_PAGE_DATA_SUCCESS";
export const GET_INSTAGRAM_PAGE_DATA_FAILURE =
	"GET_INSTAGRAM_PAGE_DATA_FAILURE";

export const RESET_INSTAGRAM_PAGE_DATA = "RESET_INSTAGRAM_PAGE_DATA";
export const RESET_INSTAGRAM_PAGE_DIMENSIONS =
	"RESET_INSTAGRAM_PAGE_DIMENSIONS";

export const TOGGLE_MODAL_OPEN = "TOGGLE_MODAL_OPEN";
export const TOGGLE_MODAL_CLOSED = "TOGGLE_MODAL_CLOSED";

export const INSTAGRAM_POST_TYPE_IMAGE = "INSTAGRAM_POST_TYPE_IMAGE";
export const INSTAGRAM_POST_TYPE_VIDEO = "INSTAGRAM_POST_TYPE_VIDEO";
export const INSTAGRAM_POST_TYPE_CAROUSEL = "INSTAGRAM_POST_TYPE_CAROUSEL";

export const CHANGE_WIDTH_AND_HEIGHT = "CHANGE_WIDTH_AND_HEIGHT";
export const IS_MOBILE = "IS_MOBILE";

export const VIDEO_PLAYING = "VIDEO_PLAYING";
export const VIDEO_PAUSED = "VIDEO_PAUSED";

export const NAVIGATION_CHANGE_TYPES = {
	VISIBLE: "VISIBLE",
	HIDDEN: "HIDDEN",
	SCROLLING: "SCROLLING",
	SCROLLED_TO_TARGET: "SCROLLED_TO_TARGET",
	NOT_SCROLLED_TO_TARGET: "NOT_SCROLLED_TO_TARGET"
};

export const INSTAGRAM_CHANGE_TYPES = {
	NEXT_POST: "NEXT_POST",
	PREV_POST: "PREV_POST",
	NEXT_CAROUSEL_SLIDE: "NEXT_CAROUSEL_SLIDE",
	PREV_CAROUSEL_SLIDE: "PREV_CAROUSEL_SLIDE"
};
