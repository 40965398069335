import * as types from "../constants/ActionTypes";
import { calculateMediaAspectRatio } from "../utilities/helpers";
import axios from "axios";

export const toggleModalClosed = (history) => {
	const html = document.getElementsByTagName("html")[0];
	return async (dispatch) => {
		html.classList.remove("modal-visible");
		history.push("/about");
		// Dispatch
		dispatch({
			type: types.TOGGLE_MODAL_CLOSED,
			payload: {},
		});
	};
};

export const toggleModalOpen = (post, postIndex, history, isMobile) => {
	const html = document.getElementsByTagName("html")[0];
	// Mobile
	if (isMobile) {
		return async (dispatch) => {
			dispatch(getPostMediaDimensions(post, history, isMobile));
		};
	}
	// Desktop
	if (!isMobile) {
		return async (dispatch) => {
			html.classList.add("modal-visible");
			dispatch(
				fetchInstagramPostData(
					types.TOGGLE_MODAL_OPEN,
					post,
					postIndex,
					history
				)
			);
		};
	}
};

export const getPostMediaDimensions = (post, history, isMobile) => {
	const media = document.querySelector(`.media-${post.id}`);
	const igFeedContainer = document.querySelector(".instagram");
	const igFeedContainerWidth = igFeedContainer.clientWidth - 335;
	const width = media.naturalWidth;
	const height = media.naturalHeight;
	const square = width === height;
	let maxWidth;
	let maxHeight;
	// Determine the maxWidth
	if (!square && width <= igFeedContainerWidth) {
		maxWidth = width;
	} else if (square && width <= igFeedContainerWidth) {
		maxWidth = width;
	} else if (!square && width >= igFeedContainerWidth) {
		maxWidth = igFeedContainerWidth - 150;
	} else if (square && width >= igFeedContainerWidth) {
		maxWidth = igFeedContainerWidth - 150;
	} else if (
		!square &&
		width >= igFeedContainerWidth &&
		(height >= 650 || window.innerHeight - 150)
	) {
		maxWidth = igFeedContainerWidth - 150;
		maxHeight = height - 150;
	}
	// Determine the maxHeight
	if (!square && height <= 650) {
		maxHeight = height;
	} else if (square && height <= 650) {
		maxHeight = height;
	} else if (!square && height >= 650) {
		maxHeight = window.innerHeight - 150;
	} else if (square && height >= 650) {
		maxHeight = window.innerHeight - 150;
	}
	if (window.innerWidth <= 895) {
		maxWidth = window.innerWidth;
	}
	// Calculate the dimensions
	const dimensions = calculateMediaAspectRatio(
		width,
		height,
		maxWidth,
		maxHeight
	);
	// Add the dimensions to the url
	history.push("/about/instagram/p/" + post.id + "/" + width + "_" + height);
	// Get post media dimensions
	return async (dispatch) => {
		dispatch({
			type: types.GET_INSTAGRAM_POST_DIMENSIONS,
			payload: { dimensions },
		});
		// Show the mobile page instead of a modal
		if (isMobile) {
			dispatch(showMobilePage(post, width, height, history, isMobile));
		}
	};
};

export const showMobilePage = (post, postWidth, postHeight, history) => {
	return async () => {
		// Add the dimensions to the url
		history.push(
			"/about/instagram/p/" + post.id + "/" + postWidth + "_" + postHeight
		);
	};
};

export const initialAction = (actionType) => {
	return async (dispatch) => {
		// Dispatch initial action (i.e., TOGGLE_MODAL_OPEN, NEXT_POST)
		dispatch({
			type: actionType,
		});
	};
};

export const beginFetchingPostData = () => {
	return async (dispatch) => {
		// Showing a loading spinner
		dispatch({
			type: types.GET_INSTAGRAM_POST_DATA_BEGIN,
		});
	};
};

export const fetchInstagramPostData = (
	actionType,
	post,
	postIndex,
	history
) => {
	const source = axios.CancelToken.source();
	return async (dispatch) => {
		// Dispatch initial action
		dispatch(initialAction(actionType));
		// Showing a loading spinner
		dispatch(beginFetchingPostData());
		// Get post media dimensions
		dispatch(getPostMediaDimensions(post, history));
		// If the request was successful
		const onSuccess = (actionType, post, postIndex, history) => {
			// Hand post data to the media type handler
			dispatch(postMediaTypeHandler(actionType, post, postIndex, history));
		};
		// If the request fails
		const onError = (error) => {
			dispatch({
				type: types.GET_INSTAGRAM_POST_DATA_FAILURE,
				payload: { error },
			});
		};
		// Set up the GET request
		try {
			// Fetch Instagram post
			const igPostData = await axios.get(`/instagram-post/${post.id}`, {
				cancelToken: source.token,
			});
			// If the request was successful
			return onSuccess(
				types.GET_INSTAGRAM_POST_DATA_SUCCESS,
				igPostData.data,
				postIndex,
				history
			);
		} catch (error) {
			// console.log(error); // Log errors
			return onError(error);
		}
	};
};

export const postMediaTypeHandler = (actionType, post, postIndex, history) => {
	return (dispatch) => {
		let carouselSlides = [],
			carouselSlide = null,
			carouselSlideIndex = 0,
			carouselSlidesLength = null;
		// If post is a Carousel
		if (post.children && post.media_type === "CAROUSEL_ALBUM") {
			carouselSlides = post.children.data.map((media) => media);
			carouselSlide = post.children.data.map((media) => media)[0];
			carouselSlidesLength = carouselSlides.length;
		}
		// Data object
		const postData = {
			actionType,
			post,
			postIndex,
			carouselSlides,
			carouselSlide,
			carouselSlideIndex,
			carouselSlidesLength,
			history,
		};
		// Dispatch the post data
		dispatch(postDataPayload(postData));
	};
};

export const toggleNextPost = (postIndex, history) => {
	return (dispatch, getState) => {
		const { instagramDataReducer } = getState();
		const { posts } = instagramDataReducer;
		let newPost, newPostIndex;
		// Fetch the next post
		newPostIndex =
			postIndex === posts.length - 1
				? (newPostIndex = 0)
				: (newPostIndex = postIndex + 1);
		newPost = posts[newPostIndex];
		// Fetch the next post
		dispatch(
			fetchInstagramPostData(
				types.INSTAGRAM_CHANGE_TYPES.NEXT_POST,
				newPost,
				newPostIndex,
				history
			)
		);
	};
};

export const togglePreviousPost = (postIndex, history) => {
	return (dispatch, getState) => {
		const { instagramDataReducer } = getState();
		const { posts } = instagramDataReducer;
		let newPost, newPostIndex;
		// Fetch the previous post
		newPostIndex =
			postIndex === 0
				? (newPostIndex = posts.length - 1)
				: (newPostIndex = postIndex - 1);
		postIndex = newPostIndex;
		newPost = posts[newPostIndex];
		// Fetch the previous post
		dispatch(
			fetchInstagramPostData(
				types.INSTAGRAM_CHANGE_TYPES.PREV_POST,
				newPost,
				newPostIndex,
				history
			)
		);
	};
};

export const toggleCarouselSlides = (
	actionType,
	post,
	postIndex,
	carouselSlides,
	carouselSlideIndex,
	history
) => {
	return (dispatch) => {
		let newCarouselSlideIndex, newCarouselSlide;
		// Next slide
		if (actionType === types.INSTAGRAM_CHANGE_TYPES.NEXT_CAROUSEL_SLIDE) {
			newCarouselSlideIndex =
				carouselSlideIndex === carouselSlides.length - 1
					? (carouselSlideIndex = 0)
					: (carouselSlideIndex = carouselSlideIndex + 1);
			newCarouselSlide = carouselSlides[newCarouselSlideIndex];
		}
		// Previous slide
		if (actionType === types.INSTAGRAM_CHANGE_TYPES.PREV_CAROUSEL_SLIDE) {
			newCarouselSlideIndex =
				carouselSlideIndex === 0
					? (carouselSlideIndex = carouselSlides.length - 1)
					: (carouselSlideIndex = carouselSlideIndex - 1);
			newCarouselSlide = carouselSlides[newCarouselSlideIndex];
		}
		// Data object
		const postData = {
			actionType,
			post,
			postIndex,
			carouselSlides,
			carouselSlide: newCarouselSlide,
			carouselSlideIndex: newCarouselSlideIndex,
			carouselSlidesLength: carouselSlides.length,
			history,
		};
		// Dispatch
		dispatch(carouselDataPayload(postData));
	};
};

export const carouselDataPayload = (post) => ({
	type: post.actionType,
	payload: { post },
});

export const postDataPayload = (post) => ({
	type: post.actionType,
	payload: { post },
});
