import * as types from "../constants/ActionTypes";
import { calculateMediaAspectRatio } from "../utilities/helpers";
import axios from "axios";

export const beginFetchingPageData = () => {
	return async (dispatch) => {
		// Show loading skeleton
		dispatch({
			type: types.GET_INSTAGRAM_PAGE_DATA_BEGIN,
		});
	};
};

export const fetchInstagramPageData = (match, history) => {
	const source = axios.CancelToken.source();
	const postId = match.params.id;
	const width = match.params.dimensions.split("_")[0];
	const height = match.params.dimensions.split("_")[1];
	const dimensions = {
		width: width,
		height: height,
	};
	// Dispatch
	return async (dispatch) => {
		// If the request was successful
		const onSuccess = (post, dimensions, history) => {
			// Get post media dimensions
			dispatch(
				getPostMediaDimensions(types.GET_INSTAGRAM_PAGE_DIMENSIONS, dimensions)
			);
			// Update when browser is resized
			window.onresize = () => {
				dispatch(
					getPostMediaDimensions(
						types.GET_INSTAGRAM_PAGE_DIMENSIONS,
						dimensions
					)
				);
			};
			// Show loading skeleton
			dispatch(beginFetchingPageData());
			// Let the loading skeleton micro-interaction animate for a sec before showing the post media
			setTimeout(() => {
				dispatch(
					postMediaTypeHandler(
						types.GET_INSTAGRAM_PAGE_DATA_SUCCESS,
						post,
						history
					)
				);
			}, 500);
		};
		// If the request fails
		const onError = (error) => {
			dispatch({
				type: types.GET_INSTAGRAM_PAGE_DATA_FAILURE,
				payload: { error },
			});
		};

		try {
			const igPostData = await axios.get(`/instagram-post/${postId}`, {
				cancelToken: source.token,
			});
			// console.log(igPostData.data);
			return onSuccess(igPostData.data, dimensions, history);
		} catch (error) {
			// console.log(error);
			return onError(error);
		}
	};
};

export const getPostMediaDimensions = (actionType, postDimensions) => {
	const width = postDimensions.width;
	const height = postDimensions.height;
	const square = width === height;
	let maxWidth;
	let maxHeight;
	// Determine the maxWidth
	if (!square && width <= 897) {
		maxWidth = width;
	} else if (square && width <= 897) {
		maxWidth = width;
	} else if (!square && width >= 897) {
		maxWidth = 697;
	} else if (square && width >= 897) {
		maxWidth = 697;
	} else if (
		!square &&
		width >= 897 &&
		(height >= 650 || window.innerHeight - 150)
	) {
		maxWidth = 697;
		maxHeight = height - 150;
	}
	if (window.innerWidth <= 895) {
		maxWidth = window.innerWidth;
	}
	// Determine the maxHeight
	if (!square && height <= 650) {
		maxHeight = height;
	} else if (square && height <= 650) {
		maxHeight = height;
	} else if (!square && height >= 650) {
		maxHeight = window.innerHeight - 150;
	} else if (square && height >= 650) {
		maxHeight = window.innerHeight - 150;
	}
	// Calculate the dimensions
	const dimensions = calculateMediaAspectRatio(
		width,
		height,
		maxWidth,
		maxHeight
	);
	// Dispatch
	return (dispatch) => {
		dispatch({
			type: actionType,
			payload: { dimensions },
		});
	};
};

export const postMediaTypeHandler = (actionType, post, history) => {
	return (dispatch) => {
		let carouselSlides = [],
			carouselSlide = null,
			carouselSlideIndex = 0,
			carouselSlidesLength = null;
		// If post is a Carousel
		if (post.children && post.media_type === "CAROUSEL_ALBUM") {
			carouselSlides = post.children.data.map((media) => media);
			carouselSlide = post.children.data.map((media) => media)[0];
			carouselSlidesLength = carouselSlides.length;
		}
		// Data object
		const postData = {
			actionType,
			post,
			carouselSlides,
			carouselSlide,
			carouselSlideIndex,
			carouselSlidesLength,
			history,
		};
		// Dispatch
		dispatch(postDataPayload(postData));
	};
};

export const toggleCarouselSlides = (
	actionType,
	post,
	carouselSlides,
	carouselSlideIndex,
	history
) => {
	return (dispatch) => {
		let newCarouselSlideIndex, newCarouselSlide;
		// Next slide
		if (actionType === types.INSTAGRAM_CHANGE_TYPES.NEXT_CAROUSEL_SLIDE) {
			newCarouselSlideIndex =
				carouselSlideIndex === carouselSlides.length - 1
					? (carouselSlideIndex = 0)
					: (carouselSlideIndex = carouselSlideIndex + 1);
			newCarouselSlide = carouselSlides[newCarouselSlideIndex];
		}
		// Previous slide
		if (actionType === types.INSTAGRAM_CHANGE_TYPES.PREV_CAROUSEL_SLIDE) {
			newCarouselSlideIndex =
				carouselSlideIndex === 0
					? (carouselSlideIndex = carouselSlides.length - 1)
					: (carouselSlideIndex = carouselSlideIndex - 1);
			newCarouselSlide = carouselSlides[newCarouselSlideIndex];
		}
		// Post data
		const postData = {
			actionType,
			post,
			carouselSlides,
			carouselSlide: newCarouselSlide,
			carouselSlideIndex: newCarouselSlideIndex,
			carouselSlidesLength: carouselSlides.length,
			history,
		};
		// Dispatch
		dispatch(carouselDataPayload(postData));
	};
};

export const carouselDataPayload = (post) => ({
	type: post.actionType,
	payload: { post },
});

export const postDataPayload = (post) => ({
	type: post.actionType,
	payload: { post },
});

export const resetDataHandler = () => ({
	type: types.RESET_INSTAGRAM_PAGE_DATA,
	payload: {},
});

export const resetDimensionsHandler = () => ({
	type: types.RESET_INSTAGRAM_PAGE_DIMENSIONS,
	payload: {},
});
