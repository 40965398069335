import * as types from "../constants/ActionTypes";

const initialState = {
  isHidden: false,
  isScrolled: false
};

export default function navigationReducer(state = initialState, action) {
  switch (action.type) {
    case types.NAVIGATION_CHANGE_TYPES.VISIBLE:
      return {
        ...state,
        isHidden: action.payload
      };

    case types.NAVIGATION_CHANGE_TYPES.HIDDEN:
      return {
        ...state,
        isHidden: action.payload
      };

    case types.NAVIGATION_CHANGE_TYPES.NOT_SCROLLED_TO_TARGET:
      return {
        ...state,
        isScrolled: action.payload
      };

    case types.NAVIGATION_CHANGE_TYPES.SCROLLED_TO_TARGET:
      return {
        ...state,
        isScrolled: action.payload
      };

    default:
      return state;
  }
}
