import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import InstagramPageContainer from "../../containers/InstagramPageContainer";

class InstagramPageLayout extends Component {
	render() {
		return (
			<div className="InstagramPost">
				<main className="flex flex-col min-h-screen">
					{/* Navigation */}
					<Navigation
						classFontColor="text-black"
						classScrolledBackgroundColor="bg-grey-lighter"
						{...this.props}
					/>

					{/* Instagram page section */}
					<section className="flex-1 flex flex-wrap w-full bg-grey-lightest h-full mx-auto pt-20">
						<div className="mx-auto px-6">
							<InstagramPageContainer {...this.props} />
						</div>
					</section>

					{/* Footer section */}
					<section className="flex flex-wrap pin-x w-full bg-grey-lightest">
						<Footer classFontColor="text-black" />
					</section>
				</main>
			</div>
		);
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isHidden: PropTypes.bool,
	isScrolled: PropTypes.bool
};

InstagramPageLayout.propTypes = propTypes;

const mapStateToProps = state => ({
	isMobile: state.environmentReducer.isMobile,
	isHidden: state.navigationReducer.isHidden,
	isScrolled: state.navigationReducer.isScrolled
});

export default connect(mapStateToProps)(InstagramPageLayout);
