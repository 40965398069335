import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import Footer from "../../components/Footer";
import AboutMe from "../../components/AboutMe";
import Navigation from "../../components/Navigation";
import AnchorLink from "react-anchor-link-smooth-scroll";
import VideoBackground from "../../components/VideoBackground";
import ContentModal from "../../components/instagram/modal/ContentModal";
import InstagramFeedContainer from "../../containers/InstagramFeedContainer";

class AboutPageLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bgHeight: null,
		};
	}

	addMetaTags = () => {
		return (
			<MetaTags>
				<title>Bobby Patterson | About Me</title>
				<meta name="robots" content="index, follow" />
				<meta
					name="description"
					content="When I'm not reclined in front of my laptop in an anxiety-fueled shame spiral attempting to fill some of the gaps in my knowledge because feeling like an impostor is pretty awful and getting minimal sleep, I enjoy spending time with my family and friends, playing my guitars, drinking coffee and staring into a screen that beams the worst of humanity directly into my brain catching up on current events, lifting weights, and engaging in the relentless pursuit of knowledge and happiness."
				/>
				<meta
					name="keywords"
					content="Robert Patterson, Bobby Patterson, Full Stack Developer, Front-end Developer, UI designer, Impostor Syndrome, Anxiety, Tempe, Phoenix, Scottsdale"
				/>
				<meta property="og:url" content="https://bobbypatterson.me/about" />
				<meta property="og:title" content="Bobby Patterson | About Me" />
				<meta property="og:site_name" content="Bobby Patterson | About Me" />
				<meta
					property="og:description"
					content="When I'm not reclined in front of my laptop in an anxiety-fueled shame spiral attempting to fill some of the gaps in my knowledge because feeling like an impostor is pretty awful and getting minimal sleep, I enjoy spending time with my family and friends, playing my guitars, drinking coffee and staring into a screen that beams the worst of humanity directly into my brain catching up on current events, lifting weights, and engaging in the relentless pursuit of knowledge and happiness."
				/>
				<meta
					property="og:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@bobbypatterson" />
				<meta name="twitter:creator" content="@bobbypatterson" />
				<meta name="twitter:title" content="Bobby Patterson | About Me" />
				<meta
					name="twitter:description"
					content="When I'm not reclined in front of my laptop in an anxiety-fueled shame spiral attempting to fill some of the gaps in my knowledge because feeling like an impostor is pretty awful and getting minimal sleep, I enjoy spending time with my family and friends, playing my guitars, drinking coffee and staring into a screen that beams the worst of humanity directly into my brain catching up on current events, lifting weights, and engaging in the relentless pursuit of knowledge and happiness."
				/>
				<meta
					name="twitter:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<link rel="canonical" href="https://bobbypatterson.me/about" />
			</MetaTags>
		);
	};

	bgHeight = () => {
		this.setState({
			bgHeight: document.querySelector(".bg-image").clientHeight,
		});
	};

	componentDidMount() {
		this.bgHeight();
	}

	render() {
		const { bgHeight } = this.state;
		const { history, isMobile, modalOpen, isScrolled } = this.props;

		return (
			<div className="About">
				<main>
					{/* Meta Tags */}
					{this.addMetaTags()}

					{/* Fonzie Header */}
					<header className="flex flex-col items-center justify-start md:justify-center min-h-full md:min-h-screen-partial z-10 relative">
						<div className="w-full z-10">
							{/* About me */}
							<AboutMe />

							{/* Continue scrolling */}
							<div
								className={`scroll-down absolute pin-b pin-l pin-r ${
									isScrolled ? "" : "animate"
								}`}
							>
								<div className="information flex justify-center w-full mx-auto">
									<div className="flex flex-col items-center justify-center w-full xl:w-2/3 my-8 md:my-16 mx-6 xl:mx-44 md:px-6 xl:px-8">
										<AnchorLink
											title="Continue scrolling"
											className="text-lg md:text-xl text-white focus:outline-none"
											href="#instagram-feed"
										>
											<span className="flex">
												<svg
													className="h-5 w-5 md:h-6 md:w-6 down"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
														clip-rule="evenodd"
													></path>
												</svg>
											</span>
										</AnchorLink>
									</div>
								</div>
							</div>
						</div>

						{/* Background image */}
						<div className="bg-image background block fixed pin-t pin-l min-w-full min-h-full performance">
							<div className="bg-dark-mode-screen flex full-screen z-10" />
							<div
								className="bg-intro-blue fixed pin-t pin-l bg-right w-full h-full performance"
								style={{ height: bgHeight }}
							/>
							<div
								className="bg-fonze fixed pin-t pin-l bg-right w-full h-full performance"
								style={{ height: bgHeight }}
							/>
							<VideoBackground
								bgHeight={bgHeight}
								modalOpen={modalOpen}
								isMobile={isMobile}
							/>
						</div>
					</header>

					<section className="flex flex-wrap w-full z-20">
						{/* Navigation */}
						<Navigation
							classFontColor="text-white"
							classScrolledBackgroundColor="bg-intro-blue"
							{...this.props}
						/>

						{/* Instagram section */}
						<section
							id="instagram-feed"
							className="flex flex-wrap w-full bg-white relative slant-top-reverse slant-top-bottom-reverse mb-8 md:mb-0 py-12 pb-8 md:pb-24 z-20"
						>
							{/* Instagram thumbnails */}
							<div className="container w-full max-w-screen-xl mx-auto px-6">
								<div className="instagram my-4">
									<h2 className="flex flex-wrap text-2xl font-bold text-black">
										Do it for The Gram{" "}
										<small className="font-semibold text-base opaque ml-5 md:ml-3 my-2 md:my-0">
											(How do you do,{" "}
											<span className="text-sm">
												<sup>fellow kids?)</sup>
											</span>
										</small>
									</h2>
									<div className="flex w-full lg:w-5/6 text-sm font-semibold text-black opaque leading-tight tracking-wide my-2">
										It's totally fine that you don't care about my Instagram
										photos, I just wanted to tinker with the API
									</div>
								</div>
								{/* Instagram container */}
								<InstagramFeedContainer history={history} />
							</div>
						</section>

						{/* Footer section */}
						<section className="flex flex-wrap w-full bg-transparent z-10">
							<Footer classFontColor="text-white" />
						</section>
					</section>
				</main>

				{/* Instagram modal is separate from everything */}
				<ContentModal {...this.props} />
			</div>
		);
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isFetchingPost: PropTypes.bool,
	modalOpen: PropTypes.bool,
	isHidden: PropTypes.bool,
	isScrolled: PropTypes.bool,
	isFetchingPage: PropTypes.bool,
	error: PropTypes.object,
	posts: PropTypes.array,
	post: PropTypes.object,
	dimensions: PropTypes.object,
};

AboutPageLayout.propTypes = propTypes;

const mapStateToProps = (state) => ({
	isMobile: state.environmentReducer.isMobile,
	isFetchingPost: state.instagramModalReducer.loading,
	modalOpen: state.instagramModalReducer.modalOpen,
	isHidden: state.navigationReducer.isHidden,
	isScrolled: state.navigationReducer.isScrolled,
	isFetchingPage: state.instagramDataReducer.loading,
	error: state.instagramDataReducer.error,
	posts: state.instagramDataReducer.posts,
	post: state.instagramModalReducer.post,
	dimensions: state.instagramModalReducer.dimensions,
});

export default connect(mapStateToProps)(AboutPageLayout);
