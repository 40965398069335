import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InstagramFeed from "../components/instagram/InstagramFeed";
import { getInstagramData } from "../actions/instagramDataAction";

class InstagramFeedContainer extends Component {
	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(getInstagramData());
	}

	render() {
		return (
			<div>
				<InstagramFeed {...this.props} />
			</div>
		);
	}
}

const propTypes = {
	loading: PropTypes.bool.isRequired,
	error: PropTypes.object,
	posts: PropTypes.array,
	post: PropTypes.object,
	modalLoading: PropTypes.bool,
	modalOpen: PropTypes.bool,
	isMobile: PropTypes.bool
};

InstagramFeedContainer.propTypes = propTypes;

const mapStateToProps = state => ({
	loading: state.instagramDataReducer.loading,
	error: state.instagramDataReducer.error,
	posts: state.instagramDataReducer.posts,
	post: state.instagramModalReducer.post,
	modalLoading: state.instagramModalReducer.modalLoading,
	modalOpen: state.instagramModalReducer.modalOpen,
	isMobile: state.environmentReducer.isMobile
});

export default connect(mapStateToProps)(InstagramFeedContainer);
