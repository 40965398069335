import React, { Component } from "react";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import ReactMoment from "react-moment";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinnerWP";

class BlogPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      posts: [],
      error: null
    };
    // Set the Axios Cancel Token
    this.source = axios.CancelToken.source();
  }

  getBlogPosts = async () => {
    try {
      // Make the API Get Request
      const response = await axios.get("/blog-posts", {
        cancelToken: this.source.token
      });
      // Get the posts
      this.setState({
        loading: false,
        posts: response.data
      });
    } catch (error) {
      if (axios.isCancel(error)) {
        // Log the error message
        console.log(error.message);
      } else {
        this.setState({
          loading: false,
          error: error.message
        });
        // Log the error message
        console.log(error.message);
      }
    }
  };

  createMarkup = html => {
    return { __html: html };
  };

  showBlogPosts = () => {
    const { loading, posts } = this.state;

    return loading ? (
      <LoadingSpinner />
    ) : (
      <div>
        {posts.map((post, index) => (
          <article
            className="card overflow-hidden rounded-lg shadow-lg mb-12"
            key={index}
          >
            <Link
              className="no-underline"
              title={post.title.rendered}
              to={{ pathname: `/blog/${post.slug}` }}
            >
              <div
                className={`container lg:flex flex-row w-full h-full bg-white justify-center ${this.rowReverse(
                  index
                )}`}
              >
                <div
                  title={sanitizeHtml(post.title.rendered)}
                  className="flex h-48 lg:h-auto lg:w-64 flex-none bg-center bg-cover text-center overflow-hidden"
                  style={this.inlineStyle(post)}
                />
                <div className="post-data w-full px-6 py-4">
                  {/* Title */}
                  <h2
                    className="text-xl font-bold leading-normal text-black mb-1"
                    dangerouslySetInnerHTML={this.createMarkup(
                      sanitizeHtml(post.title.rendered)
                    )}
                  />

                  {/* Date */}
                  <div className="text-xs font-semibold text-black opaque tracking-wide uppercase">
                    <ReactMoment fromNow>{post.date}</ReactMoment>
                  </div>

                  {/* Excerpt */}
                  <div
                    className="text-base font-medium text-black opaque leading-normal mt-4 mb-2"
                    dangerouslySetInnerHTML={this.createMarkup(
                      sanitizeHtml(post.excerpt.rendered, {
                        allowedTags: ["p", "b", "i", "em", "strong"]
                      })
                    )}
                  />
                </div>
              </div>
            </Link>
          </article>
        ))}
      </div>
    );
  };

  errorPartial = () => {
    return (
      <div>
        <div className="flex items-center justify-center text-sm font-semibold text-black opaque leading-normal tracking-wide">
          <span role="img" className="text-lg" aria-label="Skull Emoji">
            💀
          </span>
          Something went wrong...
        </div>
      </div>
    );
  };

  inlineStyle = post => {
    const divStyle = {
      backgroundImage: `url(${post.acf.media_thumbnail.url})`
    };

    return divStyle;
  };

  rowReverse = index => {
    if (index % 2 !== 0) {
      return "flex-row-reverse lg:rounded-t-none lg:rounded-l-none lg:rounded-r";
    } else {
      return "flex-row rounded-t lg:rounded-t-none lg:rounded-l";
    }
  };

  componentDidMount() {
    this.getBlogPosts();
  }

  componentWillUnmount() {
    // Cancel the Get Request when this component unmounts
    this.source.cancel("API request cancelled");
  }

  render() {
    const { error } = this.state;

    return error ? this.errorPartial() : this.showBlogPosts();
  }
}

export default BlogPosts;
