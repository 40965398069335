import * as types from "../constants/ActionTypes";
import axios from "axios";

export const getInstagramData = () => {
	const source = axios.CancelToken.source();

	return async dispatch => {
		// Begin by showing a loading spinner
		dispatch({
			type: types.GET_INSTAGRAM_DATA_BEGIN
		});
		// If the request was successful
		const onSuccess = posts => {
			dispatch({
				type: types.GET_INSTAGRAM_DATA_SUCCESS,
				payload: { posts }
			});
		};
		// If the request failed
		const onError = error => {
			dispatch({
				type: types.GET_INSTAGRAM_DATA_FAILURE,
				payload: { error }
			});
		};
		// Set up the GET request
		try {
			// Fetch Instagram posts
			const igPosts = await axios.get("/instagram-posts", {
				cancelToken: source.token
			});
			// Log the response
			// console.log(igPosts.data);
			// Data received
			return onSuccess(igPosts.data);
		} catch (error) {
			// Handle any errors
			console.log(error);
			return onError(error);
		}
	};
};
