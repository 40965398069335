import React, { Component } from "react";
import {
	toggleModalClosed,
	toggleNextPost,
	togglePreviousPost,
} from "../../../actions/instagramModalAction";

class PostButtonControls extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buttonDimensions: {},
		};
	}

	getPostDimensions = () => {
		const { dimensions } = this.props;
		const dynamicHeight = document.querySelector(".media-container")
			.clientHeight;
		const buttonDimensions = {
			height: dimensions.height,
		};
		const dynamicButtonDimensions = {
			height: dynamicHeight >= 600 ? dynamicHeight + 60 : dynamicHeight,
		};

		if (window.innerWidth >= 896) {
			this.setState({
				buttonDimensions,
			});
		}
		if (window.innerWidth <= 895) {
			this.setState({
				buttonDimensions: dynamicButtonDimensions,
			});
		}
	};

	// Toggle the modal closed
	closeModal = () => {
		const { dispatch, history } = this.props;
		// Do nothing
		dispatch(toggleModalClosed(history));
	};

	// Toggle the previous post
	togglePreviousPost = () => {
		const {
			dispatch,
			history,
			post: { postIndex },
		} = this.props;
		// Dispatch
		dispatch(togglePreviousPost(postIndex, history));
	};

	// Toggle the next post
	toggleNextPost = () => {
		const {
			dispatch,
			history,
			post: { postIndex },
		} = this.props;
		// Dispatch
		dispatch(toggleNextPost(postIndex, history));
	};

	showPostButtonControls = () => {
		const { buttonDimensions } = this.state;

		return (
			<div>
				{/* Buttons container */}
				<div className="button-container absolute pin-t pin-l w-full h-full pointer-events-none z-40">
					{/* Close button */}
					<div className="close-button-container">
						<button
							title="Close (esc)"
							className="flex items-center justify-center close-button"
							onClick={() => this.closeModal()}
						>
							<svg
								className="hover:bg-white-opaque rounded-full p-2 w-9 h-9 text-white"
								stroke="currentColor"
								fill="none"
								viewBox="0 0 24 24"
							>
								<path
									stroke-linecap="round"
									stroke-linejoin="round"
									stroke-width="2"
									d="M6 18L18 6M6 6l12 12"
								></path>
							</svg>
						</button>
					</div>
					{/* Next & Previous buttons */}
					<div className="buttons">
						{/* Previous post button */}
						<button
							title="Go to previous (<)"
							className="prev-button flex items-center justify-center focus:outline-none text-white"
							onClick={() => this.togglePreviousPost()}
						>
							<svg
								className="hover:bg-white-opaque rounded-full p-2 w-9 h-9 reverse"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</button>
						{/* Next post button */}
						<button
							title="Go to next (>)"
							className="next-button flex items-center justify-center focus:outline-none text-white"
							onClick={() => this.toggleNextPost()}
						>
							<svg
								className="hover:bg-white-opaque rounded-full p-2 w-9 h-9"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</button>
					</div>
				</div>
				{/* Modal background */}
				<div
					className="modal-background block absolute pin-t pin-b pin-l pin-r w-full h-full"
					onClick={() => this.closeModal()}
					role="button"
				/>
			</div>
		);
	};

	keysHandler = (e) => {
		switch (e.keyCode) {
			// Escape key
			case 27:
				this.closeModal();
				break;

			// Left arrow
			case 37:
				this.togglePreviousPost();
				break;

			// Right arrow
			case 39:
				this.toggleNextPost();
				break;

			// Do nothing
			default:
				return null;
		}
	};

	addEventListeners = () => {
		document.addEventListener("keydown", this.keysHandler);
		window.addEventListener("resize", this.getPostDimensions);
	};

	removeEventListeners = () => {
		document.removeEventListener("keydown", this.keysHandler);
		window.removeEventListener("resize", this.getPostDimensions);
	};

	componentDidMount() {
		this.getPostDimensions();
		this.addEventListeners();
	}

	componentWillUnmount() {
		this.removeEventListeners();
	}

	render() {
		return this.showPostButtonControls();
	}
}

export default PostButtonControls;
