import * as types from "../constants/ActionTypes";

const initialState = {
	loading: true,
	modalOpen: false,
	post: null,
	dimensions: null,
	error: null,
};

export default function instagramModalReducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_INSTAGRAM_POST_DATA_BEGIN:
			return {
				...state,
				loading: true,
				error: null,
			};

		case types.GET_INSTAGRAM_POST_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				post: action.payload.post,
			};

		case types.GET_INSTAGRAM_POST_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				post: null,
			};

		case types.TOGGLE_MODAL_OPEN:
			return {
				...state,
				modalOpen: true,
			};

		case types.TOGGLE_MODAL_CLOSED:
			return {
				...state,
				modalOpen: false,
			};

		case types.GET_INSTAGRAM_POST_DIMENSIONS:
			return {
				...state,
				dimensions: action.payload.dimensions,
			};

		case types.INSTAGRAM_CHANGE_TYPES.NEXT_POST:
			return {
				...state,
				loading: true,
			};

		case types.INSTAGRAM_CHANGE_TYPES.PREV_POST:
			return {
				...state,
				loading: true,
			};

		case types.INSTAGRAM_CHANGE_TYPES.NEXT_CAROUSEL_SLIDE:
			return {
				...state,
				post: action.payload.post,
			};

		case types.INSTAGRAM_CHANGE_TYPES.PREV_CAROUSEL_SLIDE:
			return {
				...state,
				post: action.payload.post,
			};

		default:
			return state;
	}
}
