import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import sanitizeHtml from "sanitize-html";
import ReactMoment from "react-moment";
import axios from "axios";
import { filter } from "lodash";
import LoadingSpinner from "../../components/LoadingSpinnerWP";

class BlogPost extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			post: null,
		};
		// Set the Axios Cancel Token
		this.source = axios.CancelToken.source();
	}

	addMetaTags = (post) => {
		return (
			<MetaTags>
				<title>Bobby Patterson | {post.title.rendered}</title>
				<meta name="robots" content="index, follow" />
				<meta name="description" content={post.excerpt.rendered} />
				<meta name="keywords" content={`Blog, Bobby Patterson`} />
				<meta
					property="og:url"
					content={`https://bobbypatterson.me/blog/${post.slug}`}
				/>
				<meta
					property="og:title"
					content={`Bobby Patterson | ${post.title.rendered}`}
				/>
				<meta
					property="og:site_name"
					content={`Bobby Patterson | ${post.title.rendered}`}
				/>
				<meta property="og:description" content={post.excerpt.rendered} />
				<meta
					property="og:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@bobbypatterson" />
				<meta name="twitter:creator" content="@bobbypatterson" />
				<meta
					name="twitter:title"
					content={`Bobby Patterson | ${post.title.rendered}`}
				/>
				<meta name="twitter:description" content={post.excerpt.rendered} />
				<meta
					name="twitter:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<link
					rel="canonical"
					href={`https://bobbypatterson.me/blog/${post.slug}`}
				/>
			</MetaTags>
		);
	};

	getBlogPost = async () => {
		const { match, history } = this.props;

		try {
			// Make the API Get Request
			const response = await axios.get("/blog-posts", {
				cancelToken: this.source.token,
			});
			// Get the Post from the Response data and filter through it
			const postSlug = match.params.post;
			const posts = response.data;
			const post = filter(posts, ["slug", postSlug])[0];
			// Get the correct post, otherwise push 404 error page
			if (post && post.slug === postSlug) {
				this.setState({
					loading: false,
					post,
				});
			} else {
				// Redirect to 404 page
				history.push("/404");
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				// Log the error message
				console.log(error.message);
			} else {
				this.setState({
					loading: false,
					error: error.message,
				});
				// Log the error message
				console.log(error.message);
			}
		}
	};

	errorPartial = () => {
		return (
			<div>
				<div className="flex items-center justify-center text-sm font-semibold text-black opaque leading-normal tracking-wide">
					<span role="img" className="text-lg" aria-label="Skull Emoji">
						💀
					</span>
					Something went wrong...
				</div>
			</div>
		);
	};

	componentDidMount() {
		this.getBlogPost();
	}

	componentWillUnmount() {
		// Cancel the Get Request when this component unmounts
		this.source.cancel("API request cancelled");
	}

	createMarkup = (html) => {
		return { __html: html };
	};

	render() {
		const { error, loading, post } = this.state;

		return (
			<div>
				<article>
					<Link
						title="Go back"
						className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline mr-auto"
						to="/blog"
					>
						<span className="flex pr-2">
							<svg
								className="h-3 w-3 reverse"
								viewBox="0 0 20 20"
								fill="currentColor"
							>
								<path
									fill-rule="evenodd"
									d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
									clip-rule="evenodd"
								></path>
							</svg>
						</span>
						<span className="underline-indigo">Back</span>
					</Link>

					{/* Divider */}
					<div className="border-t-2 border-grey-lighter mt-4 mb-6" />
					{error ? (
						this.errorPartial()
					) : loading ? (
						<LoadingSpinner />
					) : (
						<div>
							{/* Meta tags */}
							{this.addMetaTags(post)}
							<div className="flex w-full flex-col items-start mb-4">
								<h2
									className="text-2xl leading-tight text-black font-bold"
									dangerouslySetInnerHTML={this.createMarkup(
										sanitizeHtml(post.title.rendered)
									)}
								/>
								<div className="text-base text-black font-semibold leading-normal mb-2">
									{post.acf.subtitle}
								</div>
								<div className="text-xs font-semibold text-black opaque tracking-wide uppercase">
									<ReactMoment fromNow>{post.date}</ReactMoment>
								</div>
							</div>

							<div
								className="text-lg text-black leading-normal mt-8 mb-1"
								dangerouslySetInnerHTML={this.createMarkup(
									sanitizeHtml(post.content.rendered, {
										allowedTags: [
											"div",
											"p",
											"b",
											"i",
											"em",
											"strong",
											"img",
											"iframe",
										],
										allowedAttributes: {
											div: ["class"],
											img: ["src", "class"],
											iframe: ["src", "class"],
										},
										allowedSchemes: ["https"],
									})
								)}
							/>

							{/* Divider */}
							<div className="border-t-2 border-grey-lighter mt-10 mb-4" />

							{/* Back button */}
							<Link
								title="Go back"
								className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline mr-auto"
								to="/blog"
							>
								<span className="inline-block pr-2">←</span>
								<span className="underline-indigo">Back</span>
							</Link>
						</div>
					)}
				</article>
			</div>
		);
	}
}

export default BlogPost;
