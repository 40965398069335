import React, { Component } from "react";

class ErrorSkeleton extends Component {
	tvStatic = () => {
		let canvas = document.querySelector(".tv-static"),
			context = canvas.getContext("gl") || canvas.getContext("2d"),
			scaleFactor = 1.25, // Noise size
			samples = [],
			sampleIndex = 0,
			scanOffsetY = 0,
			scanSize = 0,
			FPS = 50,
			scanSpeed = FPS * 20, // 15 seconds from top to bottom
			SAMPLE_COUNT = 10;

		// window.onresize = function() {
		canvas.width = canvas.offsetWidth / scaleFactor;
		canvas.height = canvas.width / (canvas.offsetWidth / canvas.offsetHeight);
		scanSize = canvas.offsetHeight / scaleFactor / 3;

		samples = [];
		for (let i = 0; i < SAMPLE_COUNT; i++) {
			samples.push(generateRandomSample(context, canvas.width, canvas.height));
		}

		function interpolate(x, x0, y0, x1, y1) {
			return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
		}

		function generateRandomSample(context, w, h) {
			let intensity = [];
			let random = 0;
			let factor = h / 50;
			let trans = 1 - Math.random() * 0.05;

			let intensityCurve = [];
			for (let i = 0; i < Math.floor(h / factor) + factor; i++)
				intensityCurve.push(Math.floor(Math.random() * 15));

			for (let i = 0; i < h; i++) {
				let value = interpolate(
					i / factor,
					Math.floor(i / factor),
					intensityCurve[Math.floor(i / factor)],
					Math.floor(i / factor) + 1,
					intensityCurve[Math.floor(i / factor) + 1]
				);
				intensity.push(value);
			}

			let imageData = context.createImageData(w, h);
			for (let i = 0; i < w * h; i++) {
				let k = i * 4;
				// Lighter or darker noise
				let color = Math.floor(72 * Math.random()); // 36
				// Optional: add an intensity curve to try to simulate scan lines
				color += intensity[Math.floor(i / w)];
				imageData.data[k] = imageData.data[k + 1] = imageData.data[
					k + 2
				] = color;
				imageData.data[k + 3] = Math.round(255 * trans);
			}
			return imageData;
		}

		function render() {
			context.putImageData(samples[Math.floor(sampleIndex)], 0, 0);

			sampleIndex += 20 / FPS; // 1/FPS == 1 second
			if (sampleIndex >= samples.length) sampleIndex = 0;

			let grd = context.createLinearGradient(
				0,
				scanOffsetY,
				0,
				scanSize + scanOffsetY
			);

			// grd.addColorStop(0, "rgba(255,255,255,0)");
			// grd.addColorStop(0.1, "rgba(255,255,255,0)");
			// grd.addColorStop(0.2, "rgba(255,255,255,0.2)");
			// grd.addColorStop(0.3, "rgba(255,255,255,0.0)");
			// grd.addColorStop(0.45, "rgba(255,255,255,0.1)");
			// grd.addColorStop(0.5, "rgba(255,255,255,0.5)");
			// grd.addColorStop(0.55, "rgba(255,255,255,0.55)");
			// grd.addColorStop(0.6, "rgba(255,255,255,0.25)");
			// grd.addColorStop(0.8, "rgba(255,255,255,0.15)");
			// grd.addColorStop(0.2, "rgba(255,255,255,0)");

			context.fillStyle = grd;
			context.fillRect(0, scanOffsetY, canvas.width, scanSize + scanOffsetY);
			context.globalCompositeOperation = "lighter";

			scanOffsetY += canvas.height / scanSpeed;
			if (scanOffsetY > canvas.height) scanOffsetY = -(scanSize / 2);

			window.requestAnimationFrame(render);
		}
		window.onresize();
		window.requestAnimationFrame(render);
	};

	showErrorSkeleton = () => {
		const { dimensions } = this.props;
		return (
			// Page media begin
			<article className="error-skeleton modal m-auto w-full h-full relative pointer-events-auto shadow-lg rounded-lg overflow-hidden z-50">
				<div className="content max-w-full bg-white relative">
					<div className="media-container flex items-center justify-center bg-black w-full h-full min-dimensions overflow-hidden">
						<div
							className="image-container w-full h-full relative overflow-hidden"
							style={dimensions}
						>
							<div className="flex w-full h-full instagram-img-container">
								<div className="flex items-center justify-center w-full h-full relative">
									<div className="absolute pin-t pin-b pin-l pin-r flex items-center justify-center text-lg font-normal text-white opaque leading-normal">
										Looks like something went wrong
										<span
											role="img"
											className="text-xl ml-2"
											aria-label="Screaming in Fear emoji"
										>
											😱
										</span>
									</div>
									<canvas className="tv-static w-full h-full"></canvas>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* Sidebar */}
				<div className="sidebar inline-block bg-white relative md:absolute pin-t pin-b pin-r p-6">
					<div className="flex flex-col h-full">
						<header className="profile-info flex items-center">
							{/* Profile pic */}
							<div className="profile-picture flex items-center w-12 h-12 md:w-10 md:h-10 rounded-full overflow-hidden mr-3">
								<div className="w-12 h-12 error-skeleton bg-grey-dark rounded-full"></div>
							</div>
							{/* Username */}
							<div className="username flex">
								<div className="error-skeleton bg-grey-dark w-48 h-2 rounded-lg"></div>
							</div>
						</header>
						<div className="border-t-2 border-grey-lightest mt-6 mb-4" />
						{/* Caption */}
						<div className="w-full mt-2">
							<div className="flex mb-3">
								{/* Username */}
								<div className="error-skeleton bg-grey-dark w-3/4 mr-2 h-2 mr-2 rounded-lg"></div>
								<div className="error-skeleton bg-grey w-1/5 h-2 rounded-lg"></div>
							</div>
							<div className="error-skeleton bg-grey w-full h-2 mb-2 rounded-lg"></div>
							<div className="error-skeleton bg-grey w-48 h-2 mb-2 rounded-lg"></div>
						</div>
						{/* Date */}
						<div className="error-skeleton bg-grey w-16 h-2 mt-4 rounded-lg"></div>
						{/* Bottom link */}
						<div className="flex items-center justify-center mt-auto">
							<div
								title="Open link on instagram.com"
								className="inline-flex items-center text-lg md:text-lg md:text-base text-black font-semibold no-underline"
							>
								<span className="underline-indigo">
									Open link on instagram.com
								</span>
								<span className="flex pl-2">
									<svg
										className="h-4 w-4"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
								</span>
							</div>
						</div>
					</div>
				</div>
			</article>
		);
	};

	componentDidMount() {
		this.tvStatic();
	}

	render() {
		return this.props.dimensions && this.showErrorSkeleton();
	}
}

export default ErrorSkeleton;
