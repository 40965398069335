import React, { Component } from "react";
import LoadingSpinner from "../../LoadingSpinner";

class LoadingSkeleton extends Component {
	showLoadingSkeleton = () => {
		const { dimensions, isMobile } = this.props;
		return (
			<div>
				{/* Back button */}
				<div
					title="Go back"
					className="inline-flex w-full items-center text-lg md:text-base text-black font-semibold no-underline mr-auto mb-6"
				>
					<span className="flex pr-2">
						<svg
							className="h-3 w-3 reverse"
							viewBox="0 0 20 20"
							fill="currentColor"
						>
							<path
								fill-rule="evenodd"
								d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
								clip-rule="evenodd"
							></path>
						</svg>
					</span>
					<span className="underline-indigo">Back</span>
				</div>
				{/* Page media */}
				<div>
					<article className="post-page block -mx-6 md:m-auto w-full h-full relative pointer-events-auto md:shadow-lg md:rounded-lg md:overflow-hidden">
						<div>
							<div className="content max-w-full bg-white relative">
								<div
									className="media-container flex items-center justify-center bg-black w-full h-full min-dimensions overflow-hidden"
									style={dimensions}
								>
									<div>
										<div
											className="image-container w-full h-full relative overflow-hidden"
											style={dimensions}
										>
											<div className="flex w-full h-full instagram-img-container">
												<div className="flex items-center justify-center w-full h-full">
													<LoadingSpinner colorOverride={""} />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* Sidebar */}
						<div>
							{isMobile ? (
								<div
									className="sidebar inline-block bg-grey-lightest relative md:absolute pin-t pin-b pin-r p-6"
									style={{ width: dimensions.width }}
								>
									<div className="flex flex-col h-full">
										<header className="profile-info flex items-center">
											{/* Profile pic */}
											<div className="profile-picture flex items-center w-12 h-12 md:w-10 md:h-10 rounded-full overflow-hidden mr-3">
												<div className="w-12 h-12 loading-skeleton bg-grey-dark rounded-full"></div>
											</div>
											{/* Username */}
											<div className="username flex">
												<div className="loading-skeleton bg-grey-dark w-48 h-2 rounded-lg"></div>
											</div>
										</header>
										<div className="border-t-2 border-grey-lighter mt-6 mb-4" />
										{/* Caption */}
										<div className="w-full mt-2">
											<div className="flex mb-3">
												{/* Username */}
												<div className="loading-skeleton bg-grey-dark w-3/4 mr-2 h-2 mr-2 rounded-lg"></div>
												<div className="loading-skeleton bg-grey w-1/5 h-2 rounded-lg"></div>
											</div>
											<div className="loading-skeleton bg-grey w-full h-2 mb-2 rounded-lg"></div>
											<div className="loading-skeleton bg-grey w-48 h-2 mb-2 rounded-lg"></div>
										</div>
										{/* Date */}
										<div className="loading-skeleton bg-grey w-16 h-2 mt-4 rounded-lg"></div>
										{/* Bottom link */}
										<div className="flex items-center justify-center mt-6 md:mt-auto">
											<div
												title="Open link on instagram.com"
												className="inline-flex items-center text-lg md:text-lg md:text-base text-black font-semibold no-underline"
											>
												<span className="underline-indigo">
													Open link on instagram.com
												</span>
												<span className="flex pl-2">
													<svg
														className="h-4 w-4"
														viewBox="0 0 20 20"
														fill="currentColor"
													>
														<path
															fill-rule="evenodd"
															d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
															clip-rule="evenodd"
														></path>
													</svg>
												</span>
											</div>
										</div>
									</div>
								</div>
							) : (
								<div className="sidebar inline-block bg-grey-lightest relative md:absolute pin-t pin-b pin-r p-6">
									<div className="flex flex-col h-full">
										<header className="profile-info flex items-center">
											{/* Profile pic */}
											<div className="profile-picture flex items-center w-12 h-12 md:w-10 md:h-10 rounded-full overflow-hidden mr-3">
												<div className="w-12 h-12 loading-skeleton bg-grey-dark rounded-full"></div>
											</div>
											{/* Username */}
											<div className="username flex">
												<div className="loading-skeleton bg-grey-dark w-48 h-2 rounded-lg"></div>
											</div>
										</header>
										<div className="border-t-2 border-grey-lighter mt-6 mb-4" />
										{/* Caption */}
										<div className="w-full mt-2">
											<div className="flex mb-3">
												{/* Username */}
												<div className="loading-skeleton bg-grey-dark w-3/4 mr-2 h-2 mr-2 rounded-lg"></div>
												<div className="loading-skeleton bg-grey w-1/5 h-2 rounded-lg"></div>
											</div>
											<div className="loading-skeleton bg-grey w-full h-2 mb-2 rounded-lg"></div>
											<div className="loading-skeleton bg-grey w-48 h-2 mb-2 rounded-lg"></div>
										</div>
										{/* Date */}
										<div className="loading-skeleton bg-grey w-16 h-2 mt-4 rounded-lg"></div>
										{/* Bottom link */}
										<div className="flex items-center justify-center mt-auto">
											<div
												title="Open link on instagram.com"
												className="inline-flex items-center text-lg md:text-lg md:text-base text-black font-semibold no-underline"
											>
												<span className="underline-indigo">
													Open link on instagram.com
												</span>
												<span className="inline-block pl-2">→</span>
											</div>
										</div>
									</div>
								</div>
							)}
						</div>
					</article>
				</div>
			</div>
		);
	};

	render() {
		return this.props.dimensions && this.showLoadingSkeleton();
	}
}

export default LoadingSkeleton;
