import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import routes from "../data/routes";
import taglines from "../data/taglines";
import * as types from "../constants/ActionTypes";
import MobileNavigation from "../components/mobile/MobileNavigation";
import { navBarHidden, navBarScrolled } from "../actions/navigationAction";
import {
	scrolling,
	scrollingDown,
	scrollingUp,
} from "../actions/scrollBehaviorAction";
import { enableDarkMode } from "../actions/environmentAction";

class Navigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			primaryRoutes: [],
			isToggled: false,
			taglines: [],
		};
		this.scrollTimer = null;
		this.shakeTimer = null;
	}

	scrollBehavior = () => {
		const { dispatch, isHidden, isScrolled } = this.props;
		const scrolledDown = window.scrollY > this.prev;
		const scrolledUp = window.scrollY < this.prev;
		const targetScrolled = window.scrollY > 100;

		clearTimeout(this.scrollTimer);

		this.scrollTimer = setTimeout(() => {
			dispatch(scrolling(types.IS_SCROLLING, false));
		}, 250);

		// Hide Nav when scrolling down / Show Nav when at the top or when scrolling up
		if (!scrolledDown && !targetScrolled) {
			dispatch(scrolling(types.IS_SCROLLING, false));
			dispatch(scrollingDown(types.SCROLLING_DOWN, false));
			dispatch(scrollingUp(types.SCROLLING_UP, false));
			dispatch(navBarHidden(types.NAVIGATION_CHANGE_TYPES.VISIBLE));
		} else if (scrolledDown && targetScrolled && !isHidden) {
			dispatch(scrolling(types.IS_SCROLLING, true));
			dispatch(scrollingDown(types.SCROLLING_DOWN, true));
			dispatch(scrollingUp(types.SCROLLING_UP, false));
			dispatch(navBarHidden(types.NAVIGATION_CHANGE_TYPES.HIDDEN));
		} else if (scrolledUp && isHidden) {
			dispatch(scrolling(types.IS_SCROLLING, true));
			dispatch(scrollingUp(types.SCROLLING_UP, true));
			dispatch(scrollingDown(types.SCROLLING_DOWN, false));
			dispatch(navBarHidden(types.NAVIGATION_CHANGE_TYPES.VISIBLE));
			this.showTaglines();
		}

		// Scrolled to the target / Not scrolled to the target
		if (window.scrollY > 1 && !isScrolled) {
			dispatch(
				navBarScrolled(types.NAVIGATION_CHANGE_TYPES.SCROLLED_TO_TARGET)
			);
		} else if (window.scrollY < 1 && isScrolled) {
			dispatch(
				navBarScrolled(types.NAVIGATION_CHANGE_TYPES.NOT_SCROLLED_TO_TARGET)
			);
		}

		this.prev = window.scrollY;
	};

	clickHandler = (e) => {
		const dataLink = e.target.getAttribute("href");
		const scrollTop = document.documentElement.scrollTop;
		const url = window.location.pathname;

		if (dataLink === url && !scrollTop) {
			this.shake();
		}
	};

	shake = () => {
		const html = document.getElementsByTagName("html")[0];
		html.setAttribute("class", "shake");

		clearTimeout(this.shakeTimer);

		this.shakeTimer = setTimeout(() => {
			html.setAttribute("class", "");
		}, 350);
	};

	getRoutes = () => {
		const primaryRoutes = routes.routes.filter((route) => {
			return route.route_class === "primary";
		});

		this.setState({
			primaryRoutes,
		});
	};

	showTaglines = () => {
		const randomize = (min, maximum) => {
			return Math.floor(Math.random() * (maximum - min + 1)) + min;
		};

		const taglineArray = taglines.taglines;
		const tempTaglineLength = taglineArray.length - 1;
		const tempTagline = randomize(0, tempTaglineLength);
		const tagline = taglineArray[tempTagline];

		this.setState({
			taglines: tagline,
		});
	};

	toggleMobileNavigation = () => {
		const { isToggled } = this.state;

		this.setState({
			isToggled: !isToggled,
		});

		this.toggleVisibility(!isToggled);
	};

	toggleVisibility = (visible) => {
		const html = document.getElementsByTagName("html")[0];

		html.setAttribute("class", `${visible ? "mobile-navigation-visible" : ""}`);
	};

	enableDarkMode = () => {
		const { dispatch, darkModeEnabled } = this.props;
		const darkMode = darkModeEnabled
			? types.DARK_MODE_DISABLED
			: types.DARK_MODE_ENABLED;
		dispatch(enableDarkMode(darkMode));
	};

	addEventListeners = () => {
		const logo = document.querySelector(".logo");
		logo.addEventListener("mouseleave", this.showTaglines);
		window.addEventListener("scroll", this.scrollBehavior);
	};

	removeEventListeners = () => {
		const logo = document.querySelector(".logo");
		logo.removeEventListener("mouseleave", this.showTaglines);
		window.removeEventListener("scroll", this.scrollBehavior);
	};

	componentDidUpdate() {
		clearTimeout(this.scrollTimer);
	}

	componentDidMount() {
		this.getRoutes();
		this.showTaglines();
		this.addEventListeners();
	}

	componentWillUnmount() {
		this.removeEventListeners();
		clearTimeout(this.scrollTimer);
		clearTimeout(this.shakeTimer);
	}

	render() {
		const { taglines, primaryRoutes, isToggled } = this.state;
		const {
			isHidden,
			isScrolled,
			darkModeEnabled,
			classFontColor,
			classScrolledBackgroundColor,
		} = this.props;

		let classHide = isHidden ? "unpin" : "pin";
		let classScrolled = isScrolled ? `${classScrolledBackgroundColor}` : "";

		return (
			<div>
				<nav
					className={`navigation-bar flex fixed overflow-hidden pin-t pin-x z-50 h-16 items-center ${classHide} ${classScrolled} ${classFontColor} bg-dark-mode`}
				>
					<div className="w-full max-w-screen-xl relative mx-auto px-6">
						<div className="flex items-center">
							<div className="logo flex items-center w-full h-16">
								<Link
									to="/"
									onClick={isToggled ? this.toggleMobileNavigation : null}
								>
									<h1
										className={`name-logo text-xl ${classFontColor} tracking-wide absolute pin-t pt-3 select-none ${
											isHidden ? "" : "animate"
										}`}
									>
										bobbypatterson:~$
									</h1>
									<div
										className={`tagline text-xxs font-bold ${classFontColor} opaque tracking-wide uppercase absolute pin-b pb-3 select-none`}
									>
										{taglines}
									</div>
								</Link>
							</div>
							<div className="hidden md:flex w-full">
								<div className="navigation flex w-full justify-end items-center">
									{primaryRoutes
										.filter((active) => active.visible_in_menu)
										.map((route) => (
											<Link
												key={route.id}
												to={route.path}
												className={`navigation-link font-semibold ${classFontColor} tracking-wide no-underline px-4`}
												name={route.route_data_name}
												data-links={route.route_data_name}
												onClick={(e) => this.clickHandler(e)}
											>
												{route.route_name}
											</Link>
										))}

									{/* Change theme button */}
									<label
										title={`Dark Mode is ${darkModeEnabled ? "on" : "off"}`}
										htmlFor="toggle-theme"
										className={`navigation-link flex items-center font-semibold ${classFontColor} tracking-wide no-underline ml-16 cursor-pointer`}
									>
										<div className="mr-2">Dark Mode</div>
										<div className="relative opaque">
											<input
												type="checkbox"
												title={`Dark Mode is ${darkModeEnabled ? "on" : "off"}`}
												id="toggle-theme"
												className="hidden"
												onClick={() => this.enableDarkMode()}
											/>
											<div className="toggle-line w-8 h-5 bg-black rounded-full"></div>
											<div
												className={`toggle-circle absolute w-5 h-5 bg-white rounded-full pin-t border-3 border-black transition ${
													darkModeEnabled ? "toggled-8" : ""
												}`}
											/>
										</div>
									</label>
								</div>
							</div>
							<div className="flex md:hidden w-full">
								<div className="navigation-section primary flex w-full justify-end items-center mobile">
									<div
										role="button"
										aria-pressed={isToggled}
										className={`navigation-link flex items-center justify-end ${classFontColor} w-8 h-16 cursor-pointer focus:outline-none`}
										onClick={() => this.toggleMobileNavigation()}
									>
										<svg
											className="fill-current w-5 h-5"
											role="button"
											xmlns="http://www.w3.org/2000/svg"
											viewBox="0 0 20 20"
										>
											{isToggled ? (
												<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
											) : (
												<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
											)}
										</svg>
									</div>
								</div>
							</div>
						</div>
					</div>
				</nav>
				<MobileNavigation
					toggleMobileNavigation={this.toggleMobileNavigation}
					classBackgroundColor={classScrolledBackgroundColor}
					classFontColor={classFontColor}
					isToggled={isToggled}
				/>
			</div>
		);
	}
}

const propTypes = {
	darkModeEnabled: PropTypes.bool,
	scrolling: PropTypes.bool,
	scrollingDown: PropTypes.bool,
	scrollingUp: PropTypes.bool,
};

Navigation.propTypes = propTypes;

const mapStateToProps = (state) => ({
	darkModeEnabled: state.environmentReducer.darkModeEnabled,
	scrolling: state.scrollBehaviorReducer.scrolling,
	scrollingDown: state.scrollBehaviorReducer.scrollingDown,
	scrollingUp: state.scrollBehaviorReducer.scrollingUp,
});

export default connect(mapStateToProps)(Navigation);
