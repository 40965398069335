import * as types from "../constants/ActionTypes";

const initialState = {
  scrolling: false,
  scrollingDown: false,
  scrollingUp: false
};

export default function scrollBehaviorReducer(state = initialState, action) {
  switch (action.type) {
    case types.IS_SCROLLING:
      return {
        ...state,
        scrolling: action.payload
      };

    case types.SCROLLING_DOWN:
      return {
        ...state,
        scrollingDown: action.payload
      };

    case types.SCROLLING_UP:
      return {
        ...state,
        scrollingUp: action.payload
      };

    default:
      return state;
  }
}
