import * as types from "../constants/ActionTypes";

const initialState = {
	loading: null,
	error: null,
	post: null,
	dimensions: null
};

export default function instagramPageReducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_INSTAGRAM_PAGE_DATA_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			};

		case types.GET_INSTAGRAM_PAGE_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				post: action.payload.post
			};

		case types.GET_INSTAGRAM_PAGE_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				post: null
			};

		case types.GET_INSTAGRAM_PAGE_DIMENSIONS:
			return {
				...state,
				dimensions: action.payload.dimensions
			};

		case types.RESET_INSTAGRAM_PAGE_DATA:
			return {
				...state,
				loading: true,
				post: action.payload.post
			};

		case types.RESET_INSTAGRAM_PAGE_DIMENSIONS:
			return {
				...state,
				loading: false,
				dimensions: action.payload.dimensions
			};

		case types.INSTAGRAM_CHANGE_TYPES.NEXT_CAROUSEL_SLIDE:
			return {
				...state,
				post: action.payload.post
			};

		case types.INSTAGRAM_CHANGE_TYPES.PREV_CAROUSEL_SLIDE:
			return {
				...state,
				post: action.payload.post
			};

		default:
			return state;
	}
}
