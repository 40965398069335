import { Component } from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends Component {
	componentDidUpdate(prevProps) {
		const { location } = this.props;
		const pageChange = location !== prevProps.location;
		const instagram = location.pathname.includes("/p/");
		const prevInstagram = prevProps.location.pathname.includes("/p/");
		const bothInstagram = instagram || prevInstagram;

		if (pageChange && !bothInstagram) {
			window.scrollTo(0, 0);
		} else if (bothInstagram) {
			return;
		}
	}

	render() {
		return this.props.children;
	}
}

export default withRouter(ScrollToTop);
