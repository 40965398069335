import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import throttle from "lodash/throttle";
import axios from "axios";

class ProjectMedia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			project: null,
			playing: false,
			propHistory: [],
		};
		this.videoRefs = [];
		// Set the Axios Cancel Token
		this.source = axios.CancelToken.source();
	}

	addMetaTags = (project) => {
		return (
			<MetaTags>
				<title>Bobby Patterson | {project.title}</title>
				<meta name="robots" content="index, follow" />
				<meta name="description" content={project.description} />
				<meta
					name="keywords"
					content={`${project.title}, ${project.technology}, ${project.role}, Bobby Patterson`}
				/>
				<meta
					property="og:url"
					content={`https://bobbypatterson.me/${project.parent}/${project.slug}`}
				/>
				<meta
					property="og:title"
					content={`Bobby Patterson | ${project.title}`}
				/>
				<meta
					property="og:site_name"
					content={`Bobby Patterson | ${project.title}`}
				/>
				<meta property="og:description" content={project.description} />
				<meta
					property="og:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@bobbypatterson" />
				<meta name="twitter:creator" content="@bobbypatterson" />
				<meta
					name="twitter:title"
					content={`Bobby Patterson | ${project.title}`}
				/>
				<meta name="twitter:description" content={project.description} />
				<meta
					name="twitter:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<link
					rel="canonical"
					href={`https://bobbypatterson.me/${project.parent}/${project.slug}`}
				/>
			</MetaTags>
		);
	};

	getProject = async () => {
		const { match, history } = this.props;

		try {
			// Make the API Get Request
			const response = await axios.get(
				"/data/projects.json",
				// Make the Get Request cancel-able
				{ cancelToken: this.source.token }
			);
			// Get the Post from the Response data and filter through it
			const project = response.data.workProjects;
			const projectSlug = project.filter(
				(project) => project.slug === match.params.project
			)[0];

			if (projectSlug) {
				this.setState({
					loading: false,
					project: projectSlug,
					propHistory: history,
				});
			} else {
				this.setState({
					propHistory: history,
				});

				// Redirect to 404 page
				history.push("/404");
			}
		} catch (error) {
			if (axios.isCancel(error)) {
				// Log the error message
				console.log(error.message);
			} else {
				this.setState({
					loading: false,
					error: error.message,
				});
				// Log the error message
				console.log(error.message);
			}
		}
	};

	showProject = () => {
		const { loading, project } = this.state;

		return loading ? null : (
			<div>
				<article key={project.id}>
					<div>
						{/* Meta tags */}
						{this.addMetaTags(project)}

						{/* Back button */}
						<Link
							title="Go back"
							className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline mr-auto"
							to="/"
						>
							<span className="flex pr-2">
								<svg
									className="h-3 w-3 reverse"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
										clip-rule="evenodd"
									></path>
								</svg>
							</span>
							<span className="underline-indigo">Back</span>
						</Link>

						{/* Divider */}
						<div className="border-t-2 border-grey-lighter mt-4 mb-6" />

						<div className="flex w-full flex-col items-start mb-4">
							<h2 className="text-2xl text-black font-bold">{project.title}</h2>
							<div className="text-lg font-medium text-black opaque leading-normal my-4">
								{project.description}
							</div>
							<div className="text-base font-semibold text-black opaque tracking-wide leading-normal">
								<span className="inline-block mr-2">Role:</span>
								{project.role}
							</div>
							<div className="text-base font-semibold text-black opaque tracking-wide leading-normal">
								<span className="inline-block mr-2">Year:</span>
								{project.year}
							</div>
						</div>
						{!project.externalUrl ? null : (
							<div>
								<a
									target="_blank"
									rel="noopener noreferrer"
									title={project.externalUrl}
									className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline"
									href={project.externalUrl}
								>
									<span className="underline-indigo">Launch it</span>
									<span className="flex pl-2">
										<svg
											className="h-3 w-3"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path
												fill-rule="evenodd"
												d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
												clip-rule="evenodd"
											></path>
										</svg>
									</span>
								</a>
							</div>
						)}
						{!project.gitHubUrl ? null : (
							<div>
								<a
									target="_blank"
									rel="noopener noreferrer"
									title={project.gitHubUrl}
									className="inline-flex items-center text-lg md:text-base md:text-base text-black font-semibold no-underline"
									href={project.gitHubUrl}
								>
									<span className="underline-indigo">
										View this project on GitHub
									</span>
									<span className="flex pl-2">
										<svg
											className="h-3 w-3"
											viewBox="0 0 20 20"
											fill="currentColor"
										>
											<path
												fill-rule="evenodd"
												d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
												clip-rule="evenodd"
											></path>
										</svg>
									</span>
								</a>
							</div>
						)}

						<div className="mt-8">
							{/* Show project videos and images */}
							{this.projectVideos(project)}
							{this.projectImages(project)}
						</div>

						{/* Divider */}
						<div className="border-t-2 border-grey-lighter -mt-4 md:-mt-12 mb-4" />

						{/* Back button */}
						<Link
							title="Go back"
							className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline mr-auto"
							to="/"
						>
							<span className="flex pr-2">
								<svg
									className="h-3 w-3 reverse"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										fill-rule="evenodd"
										d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
										clip-rule="evenodd"
									></path>
								</svg>
							</span>
							<span className="underline-indigo">Back</span>
						</Link>
					</div>
				</article>
			</div>
		);
	};

	projectVideos = (project) => {
		return (
			project.videos &&
			project.videos.map((video) => (
				<div
					key={video.videoId}
					className="project-media shadow-lg sm:rounded-lg overflow-hidden -mx-6 sm:mx-0 mb-1 mb-16 md:mb-24"
				>
					<video
						ref={(ref) => (this.videoRefs[video.videoId] = ref)}
						className="flex w-full pointer-events-none sm:rounded-lg"
						preload={true.toString()}
						src={video.mp4Source}
						type="video/mp4"
						playsInline
						muted
					/>
				</div>
			))
		);
	};

	projectImages = (project) => {
		return (
			project.images &&
			project.images.map((image) => (
				<div
					key={image.imageId}
					className="project-media shadow-lg sm:rounded-lg overflow-hidden -mx-6 sm:mx-0 mb-1 mb-16 md:mb-24"
				>
					<img
						src={require("../../../assets/images/" +
							project.path +
							image.imageName)}
						alt={image.imageTitle}
						className="flex max-w-full h-auto"
					/>
				</div>
			))
		);
	};

	videoPlayHandler = () => {
		const videos = this.videoRefs;

		videos &&
			videos.forEach((video) => {
				if (video) {
					// Play
					const play = (video) => {
						this.setState({
							playing: true,
						});

						video.play();
						video.loop = true;
						video.muted = true;
						video.playbackRate = 1.5;
						video.classList.add("playing");
					};

					// Pause
					const pause = (video) => {
						this.setState({
							playing: false,
						});

						video.pause();
						video.loop = false;
						video.muted = true;
						video.classList.remove("playing");
					};

					// Pause when the modal opens, resume when it closes
					this.isVisible(video) ? play(video) : pause(video);
				}
			});
	};

	isVisible = (video) => {
		const videoRect = video.getBoundingClientRect();
		// Window
		const height = window.innerHeight || document.documentElement.clientHeight;
		const width = window.innerWidth || document.documentElement.clientWidth;
		// Video element
		const left = videoRect.left >= 0;
		const right = videoRect.right <= width;
		const top = videoRect.top + height * 0.25 >= 0; // 75% of the video is visible
		const bottom = videoRect.bottom - height * 0.25 <= height; // 75% of the video is visible
		const all = top && bottom && left && right;
		// Returns a boolean
		return all;
	};

	addEvents = () => {
		// Throttling this function improves performance considerably
		const videoPlayHandlerThrottled = throttle(this.videoPlayHandler, 1000, {
			leading: true,
			trailing: true,
		});
		window.addEventListener("scroll", videoPlayHandlerThrottled);
	};

	removeEvents = () => {
		window.removeEventListener("scroll", this.videoPlayHandler);
	};

	componentDidMount() {
		this.getProject();
		this.addEvents();
	}

	componentWillUnmount() {
		this.removeEvents();
		// Cancel the Get Request when this component unmounts
		this.source.cancel("API request cancelled");
	}

	render() {
		return <div>{this.showProject()}</div>;
	}
}

export default ProjectMedia;
