import React, { Component } from "react";
import throttle from "lodash/throttle";

class VideoBackground extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playing: false,
		};
		this.video = React.createRef();
	}

	videoPlayHandler = () => {
		const { modalOpen } = this.props;
		const video = this.video.current;

		if (video) {
			// Play
			const play = (video) => {
				this.setState({
					playing: true,
				});

				video.play();
				video.loop = true;
				video.muted = true;
			};

			// Pause
			const pause = (video) => {
				this.setState({
					playing: false,
				});

				video.pause();
				video.loop = false;
				video.muted = true;
			};

			// Pause when the modal opens or when no longer visible
			!modalOpen && this.isVisible(video) ? play(video) : pause(video);
		}
	};

	isVisible = (video) => {
		const scrollPosition = window.scrollY;
		const height = video.offsetHeight;
		const visible = scrollPosition < height;

		return visible;
	};

	showBackground = () => {
		const { bgHeight } = this.props;

		return (
			<div className="bg-video" style={{ height: bgHeight }}>
				<video
					ref={this.video}
					type="video/mp4"
					src="//giant.gfycat.com/ColorfulLividGuillemot.mp4"
					className="video hidden md:flex full-screen"
					preload="auto"
					playsInline
					autoPlay
					muted
					loop
				></video>
			</div>
		);
	};

	addEventListeners = () => {
		// Throttling this function improves performance considerably
		const videoPlayHandlerThrottled = throttle(this.videoPlayHandler, 250, {
			leading: true,
			trailing: true,
		});
		// Improves performance considerably
		window.addEventListener("scroll", videoPlayHandlerThrottled);
	};

	removeEventListeners = () => {
		window.removeEventListener("scroll", this.videoPlayHandler);
	};

	componentDidUpdate(prevProps) {
		const { modalOpen } = this.props;

		if (modalOpen !== prevProps.modalOpen) {
			this.videoPlayHandler();
		}
	}

	componentDidMount() {
		this.addEventListeners();
	}

	componentWillUnmount() {
		this.removeEventListeners();
	}

	render() {
		const { isMobile } = this.props;

		return <div>{isMobile ? null : this.showBackground()}</div>;
	}
}

export default VideoBackground;
