import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import InstagramPage from "../components/instagram/page/InstagramPage";
import {
	fetchInstagramPageData,
	resetDataHandler,
	resetDimensionsHandler
} from "../actions/instagramPageAction";

class InstagramPageContainer extends Component {
	componentDidMount() {
		const { dispatch, match, history } = this.props;

		dispatch(fetchInstagramPageData(match, history));
	}

	componentWillUnmount() {
		const { dispatch } = this.props;

		dispatch(resetDataHandler());
		dispatch(resetDimensionsHandler());
	}

	render() {
		return (
			<div>
				<div className="instagram-page flex flex-col w-full max-w-screen-xl mx-auto mt-4 lg:px-6">
					<div className="w-full md:w-auto md:m-auto">
						<InstagramPage {...this.props} />
					</div>
				</div>
			</div>
		);
	}
}

const propTypes = {
	isFetching: PropTypes.bool,
	error: PropTypes.object,
	post: PropTypes.object,
	dimensions: PropTypes.object,
	isMobile: PropTypes.bool
};

InstagramPageContainer.propTypes = propTypes;

const mapStateToProps = state => ({
	isFetchingPage: state.instagramPageReducer.loading,
	error: state.instagramPageReducer.error,
	post: state.instagramPageReducer.post,
	dimensions: state.instagramPageReducer.dimensions,
	isMobile: state.environmentReducer.isMobile
});

export default connect(mapStateToProps)(InstagramPageContainer);
