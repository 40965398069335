import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { getCurrentYear } from "../../utilities/helpers";
import Capabilities from "../../components/Capabilities";
import ProfessionalProfile from "../../components/ProfessionalProfile";
import ProjectThumbnails from "../../components/work/ProjectThumbnails";
import SideProjectThumbnails from "../../components/projects/ProjectThumbnails";

class HomePageLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bgHeight: null,
		};
	}

	addMetaTags = () => {
		return (
			<MetaTags>
				<title>Bobby Patterson | Portfolio</title>
				<meta name="robots" content="index, follow" />
				<meta
					name="description"
					content="HELLO! I'm Bobby Patterson, a multidisciplinary developer and UI designer living in Tempe, Arizona. I have experience in all aspects of the development life-cycle, but I specialize in front-end development."
				/>
				<meta
					name="keywords"
					content="Robert Patterson, Bobby Patterson, Multidisciplinary, Full Stack Developer, Front-end Developer, UI designer, Tempe, Phoenix, Scottsdale"
				/>
				<meta property="og:url" content="https://bobbypatterson.me" />
				<meta property="og:title" content="Bobby Patterson | Portfolio" />
				<meta property="og:site_name" content="Bobby Patterson | Portfolio" />
				<meta
					property="og:description"
					content="HELLO! I'm Bobby Patterson, a multidisciplinary developer and UI designer living in Tempe, Arizona. I have experience in all aspects of the development life-cycle, but I specialize in front-end development."
				/>
				<meta
					property="og:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@bobbypatterson" />
				<meta name="twitter:creator" content="@bobbypatterson" />
				<meta name="twitter:title" content="Bobby Patterson | Portfolio" />
				<meta
					name="twitter:description"
					content="HELLO! I'm Bobby Patterson, a multidisciplinary developer and UI designer living in Tempe, Arizona. I have experience in all aspects of the development life-cycle, but I specialize in front-end development."
				/>
				<meta
					name="twitter:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<link rel="canonical" href="https://bobbypatterson.me" />
			</MetaTags>
		);
	};

	bgHeight = () => {
		this.setState({
			bgLoaded: true,
			bgHeight: document.querySelector(".bg-image").clientHeight,
		});
	};

	componentDidMount() {
		this.bgHeight();
	}

	render() {
		const { bgHeight } = this.state;
		const { isMobile, history, isScrolled } = this.props;

		return (
			<div className="Home">
				<main>
					{/* Meta Tags */}
					{this.addMetaTags()}

					{/* Colorful Header */}
					<header className="flex flex-col items-center justify-start md:justify-center min-h-full md:min-h-screen-partial z-10 relative">
						<div className="w-full z-10">
							{/* Hero name */}
							{!isMobile && (
								<Hero
									firstWord="Developer & "
									words={[
										"Designer",
										"Expert Googler",
										"Decent Golfer",
										"Player of guitars",
										"Awkward Dancer",
										"Lover of Burritos",
										"Lover of Cats",
										"Listener of Punk Rock",
										"All-around Neat Guy",
									]}
								/>
							)}

							{/* Profession profile text */}
							<ProfessionalProfile />

							{/* Continue scrolling */}
							<div
								className={`scroll-down absolute pin-b pin-l pin-r ${
									isScrolled ? "" : "animate"
								}`}
							>
								<div className="information flex justify-center w-full mx-auto">
									<div className="flex flex-col items-center justify-center w-full xl:w-2/3 my-8 md:my-16 mx-6 xl:mx-44 md:px-6 xl:px-8">
										<AnchorLink
											title="Continue scrolling"
											className="text-lg md:text-xl text-white focus:outline-none"
											href="#work"
										>
											<span className="flex">
												<svg
													className="h-5 w-5 md:h-6 md:w-6 down"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
														clip-rule="evenodd"
													></path>
												</svg>
											</span>
										</AnchorLink>
									</div>
								</div>
							</div>
						</div>

						{/* Background image */}
						<div className="bg-image background block fixed pin-t pin-l min-w-full min-h-full">
							<div className="bg-dark-mode-screen flex full-screen z-10 performance"></div>
							<div
								className="bg-intro-blue bg-bedroom fixed pin-t pin-l bg-right w-full h-full performance"
								style={{ height: bgHeight }}
							/>
						</div>
					</header>

					{/* Projects */}
					<section className="flex flex-wrap w-full z-20">
						<Navigation
							classFontColor="text-white"
							classScrolledBackgroundColor="bg-intro-blue"
							{...this.props}
						/>

						{/* Work Projects */}
						<section
							id="work"
							className="flex flex-wrap w-full bg-white relative slant-top slant-top-bottom pt-8 pb-16 z-20"
						>
							<div className="container w-full max-w-screen-xl mx-auto px-6">
								<div className="projects mt-4 mb-0 md:mb-6">
									<h2 className="flex text-2xl text-black font-bold flex">
										Work Projects
									</h2>
									<div className="flex w-full lg:w-5/6 text-sm font-semibold text-black opaque leading-tight tracking-wide my-2">{`2014 – ${getCurrentYear()}`}</div>
									<ProjectThumbnails history={history} />
								</div>
							</div>
						</section>

						{/* Side Projects */}
						<section
							id="projects"
							className="flex flex-wrap w-full bg-grey-lighter relative slant-top-reverse slant-top-bottom py-16 z-20"
						>
							<div className="container w-full max-w-screen-xl mx-auto px-6">
								<div className="projects mt-4 mb-0 md:mb-6">
									<h2 className="flex text-2xl text-black font-bold flex">
										Side Projects
									</h2>
									<div className="flex w-full lg:w-5/6 text-sm font-semibold text-black opaque leading-tight tracking-wide my-2">{`2017 – ${getCurrentYear()}`}</div>
									<SideProjectThumbnails history={history} />
								</div>
							</div>
						</section>

						{/* Capabilities */}
						<section
							id="capabilities"
							className="flex flex-wrap w-full bg-white slant-top slant-top-bottom pt-8 pb-16 z-20"
						>
							<div className="container w-full max-w-screen-xl mx-auto px-6">
								<div className="capabilities mt-4 mb-0 md:mb-6">
									<h2 className="text-xl md:text-2xl text-black font-bold flex">
										Capabilities
									</h2>
									<div className="flex w-full lg:w-5/6 text-sm font-semibold text-black opaque leading-tight tracking-wide my-2">
										I do things like
									</div>
									<Capabilities />
								</div>
							</div>
						</section>
					</section>

					{/* Quote */}
					<section className="flex flex-wrap w-full bg-grey-lighter relative slant-top-reverse slant-top-bottom-reverse py-12 md:py-16 mb-8 md:mb-0 z-20">
						<div className="container w-full lg:w-3/4 mx-auto mt-8 md:mb-2 px-4">
							<div className="relative text-center">
								<figure className="font-display">
									<blockquote className="relative text-2xl md:text-3xl text-black leading-normal mx-auto italic mb-4 px-12 sm:px-16">
										<div className="absolute pin-t pin-l -mt-5 pl-2">
											<svg
												className="h-8 w-8 sm:h-12 sm:w-12 text-intro-blue fill-current"
												viewBox="0 0 40 40"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M30.216 9H35c-2.591 4.338-4.385 8.474-5.282 12.307h4.784L32.907 32H22.542l.697-4.842C24.435 19.289 27.226 13.237 30.216 9zM12.674 9h4.884c-2.69 4.338-4.485 8.474-5.382 12.307h4.784L15.365 32H5l.698-4.842C6.894 19.289 9.684 13.237 12.674 9z"
													fillRule="evenodd"
												/>
											</svg>
										</div>
										Programming is like a choose your own adventure game except
										every path leads to you lying in bed at 2am thinking about a
										bug you couldn’t fix that day.
										<div className="absolute pin-b pin-r -mb-5 pr-2">
											<svg
												className="h-8 w-8 sm:h-12 sm:w-12 text-intro-blue fill-current"
												viewBox="0 0 40 40"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M9.784 32H5c2.591-4.338 4.385-8.474 5.282-12.307H5.498L7.093 9h10.365l-.697 4.842C15.565 21.711 12.774 27.763 9.784 32zm17.542 0h-4.884c2.69-4.338 4.485-8.474 5.382-12.307H23.04L24.635 9H35l-.698 4.842C33.106 21.711 30.316 27.763 27.326 32z"
													fillRule="evenodd"
												/>
											</svg>
										</div>
									</blockquote>
									<figcaption className="uppercase text-intro-blue tracking-wide font-bold text-lg">
										<a
											target="_blank"
											rel="noopener noreferrer"
											href="https://twitter.com/iamdevloper/status/1078774188868071424"
											className="text-intro-blue hover:text-black no-underline"
										>
											@iamdevloper
										</a>
									</figcaption>
								</figure>
							</div>
						</div>
					</section>

					{/* Footer */}
					<section className="flex flex-wrap w-full bg-transparent">
						<Footer classFontColor="text-white" />
					</section>
				</main>
			</div>
		);
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isHidden: PropTypes.bool,
	isScrolled: PropTypes.bool,
};

HomePageLayout.propTypes = propTypes;

const mapStateToProps = (state) => ({
	isMobile: state.environmentReducer.isMobile,
	isHidden: state.navigationReducer.isHidden,
	isScrolled: state.navigationReducer.isScrolled,
});

export default connect(mapStateToProps)(HomePageLayout);
