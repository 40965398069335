import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import PageSidebar from "./PageSidebar";
import PageMedia from "./PageMedia";
import LoadingSkeleton from "./LoadingSkeleton";

class InstagramPage extends Component {
	showError = () => {
		return (
			<div>
				<div className="flex items-center justify-center text-sm font-semibold text-black opaque leading-normal tracking-wide">
					Looks like something went wrong
					<span
						role="img"
						className="text-lg ml-1"
						aria-label="Screaming in Fear emoji"
					>
						😱
					</span>
				</div>
			</div>
		);
	};

	showInstagramPage = () => {
		const { isFetchingPage, post } = this.props;

		return (
			<div>
				{isFetchingPage ? (
					<LoadingSkeleton {...this.props} />
				) : (
					post && (
						<div>
							<MetaTags {...this.props} />
							{/* Back button */}
							<Link
								title="Go back"
								className="inline-flex w-full items-center text-lg md:text-base text-black font-semibold no-underline mr-auto mb-6"
								to="/about"
							>
								<span className="flex pr-2">
									<svg
										className="h-3 w-3 reverse"
										viewBox="0 0 20 20"
										fill="currentColor"
									>
										<path
											fill-rule="evenodd"
											d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
											clip-rule="evenodd"
										></path>
									</svg>
								</span>
								<span className="underline-indigo">Back</span>
							</Link>
							<article
								id={post.id}
								className="post-page -mx-6 md:m-auto md:rounded-lg md:shadow-lg relative overflow-hidden z-30"
							>
								{/* Media data */}
								<PageMedia {...this.props} />
								{/* Sidebar data */}
								<PageSidebar {...this.props} />
							</article>
						</div>
					)
				)}
			</div>
		);
	};

	componentDidMount() {
		window.scrollTo(0, 0); // Scroll to the top on reload
	}

	render() {
		return this.props.error ? this.showError() : this.showInstagramPage();
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isFetchingPage: PropTypes.bool,
	error: PropTypes.object,
	post: PropTypes.object,
	dimensions: PropTypes.object,
};

InstagramPage.propTypes = propTypes;

const mapStateToProps = (state) => ({
	isMobile: state.environmentReducer.isMobile,
	isFetchingPage: state.instagramPageReducer.loading,
	error: state.instagramPageReducer.error,
	post: state.instagramPageReducer.post,
	dimensions: state.instagramPageReducer.dimensions,
});

export default connect(mapStateToProps)(InstagramPage);
