import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import Footer from "../../components/Footer";
import Navigation from "../../components/Navigation";
import BlogPosts from "../../components/blog/BlogPosts";

class BlogPostsPageLayout extends Component {
	addMetaTags = () => {
		return (
			<MetaTags>
				<title>Bobby Patterson | Blog</title>
				<meta name="robots" content="index, follow" />
				<meta
					name="description"
					content="I know you don't care to read my ramblings about life and technology, I really just felt like building something with the Wordpress REST API."
				/>
				<meta
					name="keywords"
					content="Blog, Robert Patterson, Bobby Patterson, Full Stack Developer, Front-end Developer, UI designer, Tempe, Phoenix, Scottsdale"
				/>
				<meta property="og:url" content="https://bobbypatterson.me/blog" />
				<meta property="og:title" content="Bobby Patterson | Blog" />
				<meta property="og:site_name" content="Bobby Patterson | Blog" />
				<meta
					property="og:description"
					content="I know you don't care to read my ramblings about life and technology, I really just felt like building something with the Wordpress REST API."
				/>
				<meta
					property="og:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@bobbypatterson" />
				<meta name="twitter:creator" content="@bobbypatterson" />
				<meta name="twitter:title" content="Bobby Patterson | Blog" />
				<meta
					name="twitter:description"
					content="I know you don't care to read my ramblings about life and technology, I really just felt like building something with the Wordpress REST API."
				/>
				<meta
					name="twitter:image"
					content="https://bobbypatterson.me/images/bg-bedroom.jpg"
				/>
				<link rel="canonical" href="https://bobbypatterson.me/blog" />
			</MetaTags>
		);
	};

	render() {
		const { match, history } = this.props;

		return (
			<div className="BlogPosts">
				<main className="flex flex-col min-h-screen">
					{/* Meta tags */}
					{this.addMetaTags()}

					{/* Navigation */}
					<Navigation
						classFontColor="text-black"
						classScrolledBackgroundColor="bg-grey-lighter"
						{...this.props}
					/>

					{/* Blog posts section */}
					<section className="flex-1 flex flex-wrap w-full bg-grey-lightest relative pt-20">
						<div className="container w-full lg:w-3/4 xl:w-1/2 mx-auto px-6">
							<div className="blog-posts mt-4 mb-6">
								<h2 className="flex text-2xl text-black font-bold flex">
									Blog!
								</h2>
								<div className="flex w-full lg:w-5/6 text-sm font-semibold text-black opaque leading-tight tracking-wide my-2">
									I know you don't care to read my ramblings about life and
									technology, I really just felt like building something with
									the Wordpress REST API
								</div>
							</div>
							<BlogPosts match={match} history={history} />
						</div>
					</section>

					{/* Footer section */}
					<section className="flex flex-wrap pin-x w-full bg-grey-lightest">
						<Footer classFontColor="text-black" />
					</section>
				</main>
			</div>
		);
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isHidden: PropTypes.bool,
	isScrolled: PropTypes.bool
};

BlogPostsPageLayout.propTypes = propTypes;

const mapStateToProps = state => ({
	isMobile: state.environmentReducer.isMobile,
	isHidden: state.navigationReducer.isHidden,
	isScrolled: state.navigationReducer.isScrolled
});

export default connect(mapStateToProps)(BlogPostsPageLayout);
