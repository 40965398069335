import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import IEPageLayout from "../pages/layouts/IEPageLayout";
import ModalSwitch from "./ModalSwitch";
import { internetExplorer } from "../utilities/helpers";

class App extends Component {
  render() {
    // If user's browser IE 11 or older
    if (internetExplorer()) return <IEPageLayout />;

    return (
      <Router>
        <ScrollToTop>
          <Route component={ModalSwitch} />
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
