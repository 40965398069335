import React, { Component } from "react";
import { getCurrentYear } from "../utilities/helpers";

export default class Footer extends Component {
  render() {
    const { classFontColor } = this.props;

    return (
      <footer className="container mx-auto z-10">
        <div className="footer flex items-center justify-center text-center leading-normal flex-col bg-transparent mx-auto mt-16 md:mt-24 mb-12 px-4">
          <p
            className={`text-sm font-semibold ${classFontColor} opaque tracking-wide`}
          >
            &copy; Bobby Patterson {getCurrentYear()}. All rights reserved.
            <br className="md:hidden" /> Literally all of them. That means you,
            Tim.
          </p>
        </div>
      </footer>
    );
  }
}
