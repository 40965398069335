import React, { Component } from "react";

class LoadingSkeleton extends Component {
	showLoadingSkeleton = () => {
		return (
			<div>
				<div className="instagram-feed">
					<div className="instagram-feed-container flex flex-wrap -mx-3">
						{/* Row 1 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 2 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 3 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 4 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 5 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="loading-skeleton flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		return this.showLoadingSkeleton();
	}
}

export default LoadingSkeleton;
