import React, { Component } from "react";
//import logo from "../../assets/images/logos/bobbypattersonWhite.svg";
import Name from "./Name";

class Hero extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "1Full2 1Stack2 1Developer2 1&2 1Designer2",
			print: "",
			interval: null,
			timer: null,
			isComplete: false,
			// Type Writer
			isDeleting: false,
			wordIndex: 0,
			speed: null,
			wait: 5000,
			letters: "",
		};
	}

	typeWriter = () => {
		const { isDeleting, wordIndex, speed, wait, letters } = this.state;
		const { words } = this.props;

		if (!words) return;

		// Get the hero title selector
		const heroTitle = document.querySelector(".hero-title");
		// Current index of word
		const current = wordIndex % words.length;
		// Get full text of current word
		const fullWords = words[current];

		// Set typing intervals
		if (isDeleting) {
			// Clear intervals
			clearInterval(this.typing);
			clearInterval(this.deleting);
			// Set interval
			this.typing = setInterval(() => {
				this.setState({
					speed: 100,
					letters: fullWords.substring(0, letters.length - 1),
				});
			}, speed);
		} else {
			// Clear intervals
			clearInterval(this.typing);
			clearInterval(this.deleting);
			// Set interval
			this.typing = setInterval(() => {
				this.setState({
					speed: 200,
					letters: fullWords.substring(0, letters.length + 1),
				});
			}, speed);
		}

		// If the word is complete
		if (!isDeleting && letters === fullWords) {
			// Pause and set isDeleting to true
			this.setState({
				isDeleting: true,
				speed: parseInt(wait, 10),
			});
			// Add / remove classes
			heroTitle.classList.remove("typing");
			heroTitle.classList.add("animate");
		} else if (isDeleting && letters === "") {
			// Move on to next word
			this.setState({
				wordIndex: wordIndex + 1,
				isDeleting: false,
				speed: 1000,
			});
			// Add / remove classes
			heroTitle.classList.add("typing");
			heroTitle.classList.remove("animate");
		}
	};

	showTypeWriter = () => {
		return this.state.letters;
	};

	showTextWriter = () => {
		const { isComplete, print } = this.state;

		return (
			<div
				className={`hero-title fira-mono text-lg text-white opaque h-6 ${
					isComplete ? "finished" : "typing"
				}`}
				dangerouslySetInnerHTML={{ __html: print }}
			/>
		);
	};

	resetTypeWriter = () => {
		clearInterval(this.typing);
		clearInterval(this.deleting);
	};

	writeText = () => {
		const { text } = this.state;
		const letters = text.split("");
		let print = "";

		// Wait half a sec to start animation
		const timer = setTimeout(() => {
			// Run until finished
			const interval = setInterval(() => {
				if (letters.length >= 1) {
					if (letters[0] === "1") {
						print += "<mark>";
						letters.shift();
					} else if (letters[0] === "2") {
						print += "</mark>";
						letters.shift();
					} else {
						print += letters.shift();
						this.setState({ print });
					}
				} else {
					clearInterval(interval);
					this.textClassHandler();
				}
			}, 60);
			this.setState({ interval });
		}, 500);
		this.setState({ timer });
	};

	resetHero = () => {
		const { interval, timer } = this.state;

		clearInterval(interval);
		clearTimeout(timer);
	};

	textClassHandler = () => {
		const heroTitle = document.querySelector(".hero-title");
		// Remove animation after 10 seconds
		const timer = setTimeout(() => {
			this.setState({ isComplete: true });
		}, 10000);

		if (heroTitle) {
			heroTitle.classList.remove("typing");
			heroTitle.classList.add("animate");
		}

		this.setState({ timer });
	};

	componentDidUpdate() {
		this.typeWriter();
	}

	componentDidMount() {
		this.typeWriter();
	}

	componentWillUnmount() {
		this.resetTypeWriter();
	}

	render() {
		return (
			<div className="hero hidden md:flex items-center justify-center w-full h-24 flex-col mb-12 px-3">
				<div className="hero-name flex flex-col items-center mb-2">
					<Name colorClass="text-white" />
				</div>
				<div
					className={`hero-title fira-mono text-base lg:text-lg font-semibold text-white opaque tracking-wide uppercase h-6 ${
						this.state.isComplete ? "finished" : "typing"
					}`}
				>
					{this.props.firstWord ? this.props.firstWord : null}
					{this.showTypeWriter()}
				</div>
			</div>
		);
	}
}

export default Hero;
