import React, { Component } from "react";
import SocialButtons from "./SocialButtons";

class AboutMe extends Component {
	render() {
		return (
			<div>
				<div className="container w-full max-w-screen-xl flex items-center justify-center text-white mx-auto pt-2 md:pt-0 px-6">
					<div className="flex flex-col-reverse md:flex-row items-center w-full flex-reverse">
						<div className="flex items-center justify-center w-1/2 md:w-128 md:pl-6">
							<SocialButtons />
						</div>
						<div className="flex flex-col mt-24 md:my-0 md:pl-6">
							<p className="text-lg lg:text-xl font-medium leading-normal pb-6 md:pr-16">
								<span className="text-sm lg:text-base font-bold uppercase tracking-wide mr-1">
									When I'm not
								</span>{" "}
								reclined in front of my laptop{" "}
								<span className="strikeThrough">
									in an anxiety-fueled shame spiral
								</span>{" "}
								attempting to fill some of the gaps in my knowledge{" "}
								<span className="strikeThrough">
									by constantly practicing algorithms because feeling like an
									impostor is pretty terrible
								</span>{" "}
								and getting minimal sleep, I enjoy spending time with my family
								and friends, playing my guitars, drinking coffee and{" "}
								<span className="strikeThrough">
									staring into a screen that beams the worst of humanity
									directly into my brain
								</span>{" "}
								catching up on current events, lifting weights, and engaging in
								the relentless pursuit of knowledge and happiness.
							</p>
							<div className="line-seperator w-10 mb-6 bg-white opacity-50" />
							<p className="text-lg lg:text-xl font-medium leading-normal mb-10 md:mb-0 md:pr-16">
								Email me at{" "}
								<a
									className="text-white font-semibold underline-white"
									href="mailto:&#098;&#111;&#098;&#098;&#121;&#112;&#097;&#116;&#116;&#101;&#114;&#115;&#111;&#110;&#052;&#048;&#052;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;"
								>
									&#098;&#111;&#098;&#098;&#121;&#112;&#097;&#116;&#116;&#101;&#114;&#115;&#111;&#110;&#052;&#048;&#052;&#064;&#103;&#109;&#097;&#105;&#108;&#046;&#099;&#111;&#109;
								</a>
								<br />
								Message me on{" "}
								<a
									target="_blank"
									rel="noopener noreferrer"
									className="text-white font-semibold underline-white"
									href="https://www.linkedin.com/in/hello-bobby/"
								>
									LinkedIn
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AboutMe;
