import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import throttle from "lodash/throttle";
import rootReducer from "../reducers/index";
import { loadState, saveState } from "../actions/environmentAction";

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

const configureStore = () => {
  const perisistedState = loadState();
  const store = createStoreWithMiddleware(
    rootReducer,
    perisistedState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );

  // Persist to localStorage
  store.subscribe(
    throttle(() => {
      saveState({
        environmentReducer: {
          darkModeEnabled: store.getState().environmentReducer.darkModeEnabled
        }
      });
    }),
    2000
  );

  return store;
};

export default configureStore;
