import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import GoogleAnalytics from "../components/GoogleAnalytics";
import { initEnvironment } from "../actions/environmentAction";
import ErrorPageLayout from "../pages/layouts/ErrorPageLayout";
import HomePageLayout from "../pages/layouts/HomePageLayout";
import AboutPageLayout from "../pages/layouts/AboutPageLayout";
import BlogPostsPageLayout from "../pages/layouts/BlogPostsPageLayout";
import InstagramPageLayout from "../pages/layouts/InstagramPageLayout";
import ProjectPageLayout from "../pages/layouts/work/ProjectPageLayout";
import SideProjectPageLayout from "../pages/layouts/projects/ProjectPageLayout";
import BlogSinglePostPageLayout from "../pages/layouts/blog/BlogSinglePostPageLayout";

class ModalSwitch extends Component {
	previousLocation = this.props.location;

	darkModeEnabled = () => {
		const { darkModeEnabled } = this.props;

		darkModeEnabled
			? document.body.classList.add("dark-mode")
			: document.body.classList.remove("dark-mode");
	};

	componentDidUpdate(prevProps) {
		const { location } = this.props;
		const pageRefresh = prevProps.history.action === "POP";
		const instagramUrl = location.pathname.includes("/p/");

		// Prevents the router from loading incorrect pages when the modal is toggled Open
		if (pageRefresh || !instagramUrl) {
			this.previousLocation = this.props.location;
		}
	}

	componentDidMount() {
		const { dispatch } = this.props;

		dispatch(initEnvironment());
		this.darkModeEnabled();
	}

	render() {
		const { location, isMobile } = this.props;
		// Returns true when modal is toggled Open and false when toggled Closed
		const isModal = !!(
			!isMobile &&
			this.previousLocation !== location &&
			location.pathname.includes("/p/")
		);

		return (
			<div>
				{GoogleAnalytics.init() && <GoogleAnalytics.RouteTracker />}
				<Switch location={isModal ? this.previousLocation : location}>
					<Route exact path="/" component={HomePageLayout} />
					<Route
						exact
						path="/work-projects/:project"
						component={ProjectPageLayout}
					/>
					<Route
						exact
						path="/side-projects/:project"
						component={SideProjectPageLayout}
					/>
					<Route exact path="/about" component={AboutPageLayout} />
					{isModal ? (
						<Route
							exact
							path="/about/instagram/p/:id/:dimensions?"
							component={AboutPageLayout}
						/>
					) : (
						<Route
							exact
							path="/about/instagram/p/:id/:dimensions?"
							component={InstagramPageLayout}
						/>
					)}
					<Route exact path="/blog" component={BlogPostsPageLayout} />
					<Route
						exact
						path="/blog/:post"
						component={BlogSinglePostPageLayout}
					/>
					<Route exact path="/404" component={ErrorPageLayout} />
					<Redirect to="/404" />
				</Switch>
			</div>
		);
	}
}

const propTypes = {
	darkModeEnabled: PropTypes.bool,
	isMobile: PropTypes.bool,
	width: PropTypes.number,
	height: PropTypes.number
};

ModalSwitch.propTypes = propTypes;

const mapStateToProps = state => ({
	darkModeEnabled: state.environmentReducer.darkModeEnabled,
	isMobile: state.environmentReducer.isMobile,
	width: state.environmentReducer.width,
	height: state.environmentReducer.height
});

export default connect(mapStateToProps)(ModalSwitch);
