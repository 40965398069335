import React, { Component } from "react";
import * as types from "../../../constants/ActionTypes";
import { toggleCarouselSlides } from "../../../actions/instagramModalAction";

class PostMedia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playing: false,
			muted: true,
			mediaDimensions: {},
			buttonDimensions: {},
			currentCarouselSlideIndex: 0,
			translateValue: 0,
		};
	}

	getPostDimensions = () => {
		const { dimensions } = this.props;
		const dynamicWidth = window.innerWidth;
		const dynamicHeight = "auto";

		const mediaDimensions = {
			width: dimensions.width,
			height: dimensions.height,
		};
		const dynamicMediaDimensions = {
			width: dynamicWidth,
			height: dynamicHeight,
		};

		if (window.innerWidth >= 896) {
			this.setState({
				mediaDimensions,
			});
		}
		if (window.innerWidth <= 895) {
			this.setState({
				mediaDimensions: dynamicMediaDimensions,
			});
		}
		this.resetCarouselIndex();
	};

	goToPrevCarouselSlide = () => {
		const {
			post: { carouselSlides },
		} = this.props;

		this.toggleCarouselSlides(types.INSTAGRAM_CHANGE_TYPES.PREV_CAROUSEL_SLIDE);

		if (this.state.currentCarouselSlideIndex === 0) {
			return this.setState({
				currentCarouselSlideIndex: carouselSlides.length - 1,
				translateValue: (carouselSlides.length - 1) * -this.slideWidth(),
			});
		}

		// This will not run if we met the if condition above
		this.setState((prevState) => ({
			currentCarouselSlideIndex: prevState.currentCarouselSlideIndex - 1,
			translateValue: prevState.translateValue + this.slideWidth(),
		}));
	};

	goToNextCarouselSlide = () => {
		const {
			post: { carouselSlides },
		} = this.props;

		this.toggleCarouselSlides(types.INSTAGRAM_CHANGE_TYPES.NEXT_CAROUSEL_SLIDE);

		if (this.state.currentCarouselSlideIndex === carouselSlides.length - 1) {
			return this.setState({
				currentCarouselSlideIndex: 0,
				translateValue: 0,
			});
		}

		// This will not run if we met the if condition above
		this.setState((prevState) => ({
			currentCarouselSlideIndex: prevState.currentCarouselSlideIndex + 1,
			translateValue: prevState.translateValue + -this.slideWidth(),
		}));
	};

	slideWidth = () => {
		return document.querySelector(".carousel-item").clientWidth;
	};

	resetCarouselIndex = () => {
		this.setState({
			currentCarouselSlideIndex: 0,
			translateValue: 0,
		});
	};

	toggleCarouselSlides = (actionType) => {
		const {
			dispatch,
			history,
			post: { post, postIndex, carouselSlides, carouselSlideIndex },
		} = this.props;
		dispatch(
			toggleCarouselSlides(
				actionType,
				post,
				postIndex,
				carouselSlides,
				carouselSlideIndex,
				history
			)
		);
	};

	resetVideoSettings = () => {
		const videos = document.querySelectorAll(".media-container video");
		videos.forEach((video) => {
			if (video.classList.contains("playing")) {
				video.pause();
				video.currentTime = 0;
				video.classList.remove("playing");
				video.loop = false;
			}
		});

		this.setState({
			playing: false,
		});
	};

	videoPlayHandler = (e = null, id = 0) => {
		const { playing } = this.state;
		// ID is zero by default, otherwise it corresponds to the carousel slide index
		const video = this[`video-${id}`];
		// Abort mission if not a video
		// For when a user attempts to 'spacebar click' play a non video
		if (!video) return;

		const muteButton = video.nextSibling;
		// Ignore clicks to the mute button otherwise it will affect video playback and behave in an unsavory fashion
		if (e && e.target === muteButton) return;

		const play = (video) => {
			video.play();
			video.loop = true;
			video.classList.add("playing");

			this.setState({
				playing: true,
			});
		};

		const pause = (video) => {
			video.pause();
			video.loop = false;
			video.classList.remove("playing");

			this.setState({
				playing: false,
			});
		};
		// Pause/play video
		if (playing) {
			pause(video);
		} else {
			play(video);
		}
	};

	mute = (e) => {
		e.stopPropagation();
		this.setState({
			muted: !this.state.muted,
		});
	};

	showPostMedia = () => {
		const {
			playing,
			muted,
			currentCarouselSlideIndex,
			mediaDimensions,
		} = this.state;
		const {
			post: { post, carouselSlides, carouselSlidesLength },
		} = this.props;

		return (
			<div>
				{/* Media containers */}
				<div className="content max-w-full bg-white relative">
					<div className="media-container flex items-center justify-center bg-black w-full h-full min-dimensions overflow-hidden">
						{/* Load different containers for the different types of media */}
						{(() => {
							switch (post.media_type) {
								// Carousel
								case "CAROUSEL_ALBUM":
									return (
										<div>
											<div
												className={`carousel-container bg-black w-full h-full overflow-hidden ${
													playing ? "playing" : ""
												}`}
												style={mediaDimensions}
											>
												{/* Carousel slide media content */}
												{this.carouselSlideMedia()}
												{/* Buttons container */}
												<div className="buttons pointer-events-none">
													{/* Previous slide button */}
													<button
														title="Previous slide"
														className="prev-button flex items-center justify-center h-24 w-12 focus:outline-none text-white"
														onClick={() => this.goToPrevCarouselSlide()}
													>
														<svg
															className="fill-current bg-black-opaque hover:bg-black rounded-full mx-3 p-2 w-9 h-9 reverse"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fill-rule="evenodd"
																d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
																clip-rule="evenodd"
															></path>
														</svg>
													</button>
													{/* Next slide button */}
													<button
														title="Next slide"
														className="next-button flex items-center justify-center h-24 w-12 focus:outline-none text-white"
														onClick={() => this.goToNextCarouselSlide()}
													>
														<svg
															className="fill-current bg-black-opaque hover:bg-black rounded-full mx-3 p-2 w-9 h-9"
															viewBox="0 0 20 20"
															fill="currentColor"
														>
															<path
																fill-rule="evenodd"
																d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
																clip-rule="evenodd"
															></path>
														</svg>
													</button>
												</div>
												{/* Slide counter display */}
												<div className="slide-counter-display flex items-center justify-center absolute bg-black pin-t pin-r text-xxs md:text-xs font-semibold text-white leading-0 p-2 m-3 rounded-full">
													{`${
														currentCarouselSlideIndex + 1
													}/${carouselSlidesLength}`}
												</div>
												{/* Slide count dots indicator */}
												<div className="slide-dots-indicator">
													{carouselSlides &&
														carouselSlides.map((slide, index) => (
															<div
																key={index}
																className={`dots ${
																	index === currentCarouselSlideIndex
																		? "active"
																		: ""
																}`}
															/>
														))}
												</div>
											</div>
										</div>
									);
								// Video
								case "VIDEO":
									return (
										<div>
											<div
												role="button"
												className="video-post video-container bg-black w-full h-full cursor-pointer instagram-img-container"
												onClick={(e) => this.videoPlayHandler(e)}
												style={mediaDimensions}
											>
												<video
													ref={(video) => {
														this[`video-0`] = video;
													}}
													className="video flex max-w-full w-full h-full max-h-full object-fit-cover"
													poster={post.thumbnail_url}
													src={post.media_url}
													type="video/mp4"
													playsInline
													muted={muted}
													preload={true.toString()}
												/>
												<div
													className="absolute pin-t pin-l pin-r w-full invisible pointer-events-none"
													style={mediaDimensions}
												>
													<img
														className="max-w-full w-full h-full max-h-full text-center object-fit-cover"
														src={post.thumbnail_url}
														alt={post.caption && post.caption}
													/>
												</div>
												{muted ? (
													<button
														title="unmute (m)"
														className={`mute-button focus:outline-none z-50 ${
															playing ? "block" : "hidden"
														}`}
														onClick={(e) => this.mute(e)}
													/>
												) : (
													<button
														title="mute (m)"
														className={`unmute-button focus:outline-none z-50 ${
															playing ? "block" : "hidden"
														}`}
														onClick={(e) => this.mute(e)}
													/>
												)}
												{playing ? null : (
													<div className="play-button-container absolute pin-t pin-b pin-l pin-r cursor-pointer">
														<div className="flex items-center justify-center w-full h-full">
															<button
																title="Play"
																className="play-button focus:outline-none"
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									);
								// Image
								default:
									return (
										<div>
											<div
												className="image-container bg-black w-full h-full relative overflow-hidden"
												style={mediaDimensions}
											>
												<div className="flex w-full h-full instagram-img-container">
													<img
														className="max-w-full w-full h-full max-h-full text-center z-10 object-fit-cover"
														src={post.media_url}
														alt={post.caption && post.caption}
													/>
												</div>
											</div>
										</div>
									);
							}
						})()}
					</div>
				</div>
			</div>
		);
	};

	carouselSlideMedia = () => {
		const { playing, muted, mediaDimensions, translateValue } = this.state;
		const {
			post: { post, carouselSlides, carouselSlideIndex },
		} = this.props;

		return (
			<div>
				<div
					className="carousel-media-container w-full h-full pointer-events-auto"
					style={mediaDimensions}
				>
					<div className="block relative">
						{/* Forces the Carousel to adhere to dynamic resizing */}
						<div className="flex invisible">
							<img
								className="max-w-full w-full h-full text-center object-fit-cover"
								src={post.media_url}
								alt={post.caption && post.caption}
							/>
						</div>
						<div
							className="absolute pin-t pin-l pin-r min-dimensions"
							style={mediaDimensions}
						>
							<div className="flex flex-row items-center">
								{carouselSlides &&
									carouselSlides.map((carouselSlide, index) => (
										<div
											key={index}
											id={`carousel-item-${index}`}
											className={`carousel-item will-change w-full h-full ${
												carouselSlideIndex ? "active" : ""
											}`}
											style={{
												transform: `translateX(${translateValue}px)`,
												transition:
													"transform cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s",
											}}
										>
											{(() => {
												switch (carouselSlide.media_type) {
													// Video
													case "VIDEO":
														return (
															<div
																role="button"
																className="carousel-video bg-black w-full h-full pointer-events-auto cursor-pointer"
																style={mediaDimensions}
																onClick={(e) => this.videoPlayHandler(e, index)}
															>
																<div className="flex w-full h-full instagram-img-container">
																	<video
																		ref={(video) => {
																			this[`video-${index}`] = video;
																		}}
																		className="max-w-full w-full h-full max-h-full object-fit-cover"
																		poster={carouselSlide.thumbnail_url}
																		src={carouselSlide.media_url}
																		type="video/mp4"
																		playsInline
																		muted={muted}
																		preload={true.toString()}
																	/>
																</div>
																{muted ? (
																	<button
																		title="unmute (m)"
																		className={`mute-button focus:outline-none ${
																			playing ? "block" : "hidden"
																		}`}
																		onClick={(e) => this.mute(e)}
																	/>
																) : (
																	<button
																		title="mute (m)"
																		className={`unmute-button focus:outline-none ${
																			playing ? "block" : "hidden"
																		}`}
																		onClick={(e) => this.mute(e)}
																	/>
																)}
																{playing ? null : (
																	<div className="play-button-container absolute pin-t pin-b pin-l pin-r cursor-pointer">
																		<div className="flex items-center justify-center w-full h-full">
																			<button
																				title="Play"
																				className="play-button focus:outline-none"
																			/>
																		</div>
																	</div>
																)}
															</div>
														);
													// Image
													default:
														return (
															<div
																style={mediaDimensions}
																className="carousel-image"
															>
																<div className="flex w-full h-full instagram-img-container">
																	<img
																		className="object-fit-cover"
																		alt={post.caption && post.caption}
																		src={carouselSlide.media_url}
																	/>
																</div>
															</div>
														);
												}
											})()}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	};

	keysHandler = (e) => {
		const {
			post: { carouselSlideIndex },
		} = this.props;

		switch (e.keyCode) {
			// "m" key
			case 77:
				this.mute(e);
				break;

			// Space bar
			case 32:
				this.videoPlayHandler(null, carouselSlideIndex);
				break;

			// Do nothing
			default:
				return null;
		}
	};

	addEventListeners = () => {
		document.addEventListener("keydown", this.keysHandler);
		window.addEventListener("resize", this.getPostDimensions);
	};

	removeEventListeners = () => {
		document.removeEventListener("keydown", this.keysHandler);
		window.removeEventListener("resize", this.getPostDimensions);
	};

	resetSettings = () => {
		this.addEventListeners();
		this.getPostDimensions();
		this.resetVideoSettings();
	};

	componentDidMount() {
		this.resetSettings();
	}

	componentWillUnmount() {
		this.removeEventListeners();
	}

	componentDidUpdate(prevProps) {
		const {
			post: { postIndex, carouselSlideIndex },
		} = this.props;

		if (postIndex !== prevProps.post.postIndex) {
			this.resetSettings();
		}
		if (carouselSlideIndex !== prevProps.post.carouselSlideIndex) {
			this.resetVideoSettings();
		}
	}

	render() {
		return this.showPostMedia();
	}
}

export default PostMedia;
