import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Footer from "../../../components/Footer";
import Navigation from "../../../components/Navigation";
import BlogPost from "../../../components/blog/BlogPost";

class BlogSinglePostPageLayout extends Component {
	render() {
		const { match, history } = this.props;

		return (
			<div className="BlogPost">
				<main className="flex flex-col min-h-screen">
					{/* Navigation */}
					<Navigation
						classFontColor="text-black"
						classScrolledBackgroundColor="bg-grey-lighter"
						{...this.props}
					/>

					{/* Blog post content section */}
					<section className="flex-1 flex flex-wrap w-full bg-grey-lightest relative pt-20">
						<div className="container w-full lg:w-3/4 xl:w-1/2 mx-auto px-6">
							<div className="blog-posts mt-4 mb-8">
								<BlogPost match={match} history={history} />
							</div>
						</div>
					</section>

					{/* Footer section */}
					<section className="flex flex-wrap w-full bg-grey-lightest">
						<Footer classFontColor="text-black" />
					</section>
				</main>
			</div>
		);
	}
}

const propTypes = {
	isMobile: PropTypes.bool,
	isHidden: PropTypes.bool,
	isScrolled: PropTypes.bool
};

BlogSinglePostPageLayout.propTypes = propTypes;

const mapStateToProps = state => ({
	isMobile: state.environmentReducer.isMobile,
	isHidden: state.navigationReducer.isHidden,
	isScrolled: state.navigationReducer.isScrolled
});

export default connect(mapStateToProps)(BlogSinglePostPageLayout);
