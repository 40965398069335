import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import routes from "../../data/routes";
import * as types from "../../constants/ActionTypes";
import { enableDarkMode } from "../../actions/environmentAction";

class MobileNavigation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			primaryRoutes: [],
		};
	}

	getRoutes = () => {
		const primaryRoutes = routes.routes.filter((route) => {
			return route.route_class === "primary";
		});

		this.setState({
			primaryRoutes,
		});
	};

	enableDarkMode = () => {
		const { dispatch, darkModeEnabled } = this.props;
		const darkMode = darkModeEnabled
			? types.DARK_MODE_DISABLED
			: types.DARK_MODE_ENABLED;

		dispatch(enableDarkMode(darkMode));
	};

	componentDidMount() {
		this.getRoutes();
	}

	componentWillUnmount() {
		this.getRoutes();
	}

	render() {
		const { primaryRoutes } = this.state;
		const {
			isToggled,
			toggleMobileMenuButton,
			classBackgroundColor,
			classFontColor,
			darkModeEnabled,
		} = this.props;

		return (
			<div>
				<nav
					className={`mobile-navigation-menu ${classBackgroundColor} w-full h-full fixed pin-t pin-b pin-l pin-r z-40 ${
						isToggled ? "show" : null
					}`}
				>
					<div className="mobile-navigation flex items-center justify-center flex-col w-full min-h-screen -mt-8 select-none">
						{primaryRoutes
							.filter((active) => active.visible_in_menu)
							.map((route) => (
								<Link
									key={route.id}
									to={route.path}
									data-links={route.route_data_name}
									onClick={() => toggleMobileMenuButton()}
									className={`mobile-navigation-link text-3xl font-semibold ${classFontColor} leading-loose no-underline`}
								>
									{route.route_name}
								</Link>
							))}
						{/* Change theme button */}
						<label
							title={`Dark Mode is ${darkModeEnabled ? "on" : "off"}`}
							htmlFor="toggle-theme"
							className={`mobile-navigation-link flex items-center text-2xl font-semibold ${classFontColor} leading-loose no-underline absolute pin-b my-8`}
						>
							<div className="mr-3">Dark Mode</div>
							<div className="relative opaque">
								<input
									type="checkbox"
									title={`Dark Mode is ${darkModeEnabled ? "on" : "off"}`}
									id="toggle-theme"
									className="hidden"
									onClick={() => this.enableDarkMode()}
								/>
								<div className="toggle-line w-10 h-6 bg-black rounded-full"></div>
								<div
									className={`toggle-circle absolute w-6 h-6 bg-white rounded-full pin-t border-3 border-black transition ${
										darkModeEnabled ? "toggled-10" : ""
									}`}
								/>
							</div>
						</label>
					</div>
				</nav>
			</div>
		);
	}
}

const propTypes = {
	darkModeEnabled: PropTypes.bool,
};

MobileNavigation.propTypes = propTypes;

const mapStateToProps = (state) => ({
	darkModeEnabled: state.environmentReducer.darkModeEnabled,
});

export default connect(mapStateToProps)(MobileNavigation);
