import React, { Component } from "react";

class PageNotFound extends Component {
  render() {
    return (
      <div>
        <div className="flex items-center justify-center mx-auto leading-normal">
          <div className="flex flex-col text-center">
            <h1 className="text-5xl font-bold text-white">404</h1>
            <p className="text-xl text-white opaque font-semibold">
              Page not found
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default PageNotFound;
