import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import VideoBackground from "../../components/VideoBackground";
import IEMessage from "../../components/IEMessage";
import Hero from "../../components/Hero";
import Footer from "../../components/Footer";

class IEPageLayout extends Component {
  addMetaTags = () => {
    return (
      <MetaTags>
        <title>Bobby Patterson | Your Browser is too old</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta
          name="description"
          content="This browser is obsolete and supporting it properly would require a bit more work than I have time for at the moment. My apologies."
        />
        <meta
          name="keywords"
          content="Bobby Patterson, Full Stack Developer, Front-end Developer, UI designer, Tempe, Phoenix, Scottsdale"
        />
        <meta property="og:url" content="https://bobbypatterson.me/error" />
        <meta property="og:title" content="Bobby Patterson | Error" />
        <meta property="og:site_name" content="Bobby Patterson | Error" />
        <meta
          property="og:description"
          content="This browser is obsolete and supporting it properly would require a bit more work than I have time for at the moment. My apologies."
        />
        <meta
          property="og:image"
          content="https://bobbypatterson.me/images/bg-bedroom.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@bobbypatterson" />
        <meta name="twitter:creator" content="@bobbypatterson" />
        <meta name="twitter:title" content="Bobby Patterson | Error" />
        <meta
          name="twitter:description"
          content="This browser is obsolete and supporting it properly would require a bit more work than I have time for at the moment. My apologies."
        />
        <meta
          name="twitter:image"
          content="https://bobbypatterson.me/images/bg-bedroom.jpg"
        />
        <link rel="canonical" href="https://bobbypatterson.me/error" />
      </MetaTags>
    );
  };

  render() {
    const { isMobile } = this.props;

    return (
      <div className="Error">
        <main>
          {this.addMetaTags()}

          {/* Error Header */}
          <header className="flex relative">
            <section className="flex flex-col items-center justify-center w-full min-h-screen mx-auto z-10">
              <div className="-mt-32 mb-10">
                {/* Hero name */}
                <Hero
                  firstWord="Developer & "
                  words={[
                    "Designer",
                    "UI/UX'er",
                    "Expert-level Googler",
                    "Above Average Golfer",
                    "Decent Guitar Player",
                    "All Around Neat Guy",
                    "Man About Town",
                    "Venezia's Pizza Challenge winner",
                    "Lover of Burritos",
                    "Lover of Cats",
                    "Artist"
                  ]}
                />
              </div>
              <IEMessage />
            </section>

            {/* Background image */}
            <div className="bg-image background block fixed pin-t pin-l min-w-full min-h-full performance">
              <div className="bg-intro-blue fixed pin-t pin-l bg-right w-full h-full performance" />
              <VideoBackground isMobile={isMobile} />
            </div>

            {/* Footer section */}
            <section className="absolute pin-b pin-l pin-r">
              <Footer classFontColor="text-white" />
            </section>
          </header>
        </main>
      </div>
    );
  }
}

const propTypes = {
  isMobile: PropTypes.bool,
  isHidden: PropTypes.bool
};

IEPageLayout.propTypes = propTypes;

const mapStateToProps = state => ({
  isMobile: state.environmentReducer.isMobile,
  isHidden: state.navigationReducer.isHidden
});

export default connect(mapStateToProps)(IEPageLayout);
