import React, { Component } from "react";
import throttle from "lodash/throttle";
import { Link } from "react-router-dom";
import { toggleModalOpen } from "../../actions/instagramModalAction";
import LoadingSkeleton from "./LoadingSkeleton";
import ErrorSkeleton from "./ErrorSkeleton";

class InstagramFeed extends Component {
	constructor(props) {
		super(props);
		this.state = {
			playing: false,
		};
		this.postRefs = [];
		this.videoRefs = [];
	}

	toggleModalOpen = (post, index) => {
		const { dispatch, history, isMobile } = this.props;
		// Dispatch
		dispatch(toggleModalOpen(post, index, history, isMobile));
	};

	showInstagramFeed = () => {
		const { loading, error, posts } = this.props;

		return (
			<div>
				{loading ? (
					<LoadingSkeleton />
				) : posts && !error ? (
					<div className="instagram-feed flex flex-wrap -mx-2 sm:-mx-3">
						{posts.map((post, index) => (
							<article
								key={index}
								id={post.id}
								ref={(ref) => (this.postRefs[index] = ref)}
								className="post w-1/2 md:w-1/5 p-2 sm:p-3"
							>
								<div className="aspect-1x1 rounded-lg shadow-lg">
									<Link
										role="button"
										className="post-link"
										to={{
											pathname: "", // Leave blank because we'll update this dynamically later
										}}
										onClick={() => this.toggleModalOpen(post, index)}
									>
										<div className="post-content rounded-lg">
											{/* show when hovered */}
											{/* <div className="post-data flex items-center justify-center">
												<h3 className="likes text-xl sm:text-2xl font-bold text-black">
													<i
														className="fa fa-heart text-base sm:text-lg mr-2"
														aria-hidden="true"
													/>
													{post.likes.count}
												</h3>
											</div> */}
											{/* Post media */}
											{this.postMedia(post, index)}
										</div>
									</Link>
								</div>
							</article>
						))}
					</div>
				) : (
					<ErrorSkeleton />
				)}
			</div>
		);
	};

	postMedia = (post, index) => {
		// Carousel
		if (post.media_type === "CAROUSEL_ALBUM") {
			return (
				<div className="post-carousel-container">
					<img
						className={`media-${post.id}`}
						src={post.media_url}
						alt={post.caption && post.caption}
					/>
					<div className="carousel-icon" />
				</div>
			);
		}
		// Video
		if (post.media_type === "VIDEO") {
			return (
				<div className="video-container">
					<video
						ref={(ref) => (this.videoRefs[index] = ref)}
						poster={post.thumbnail_url}
						type="video/mp4"
						playsInline={true}
						preload="auto"
						muted={true}
					>
						<source src={post.media_url} type="video/mp4" />
					</video>
					<div className="video-icon" />
					<img
						className={`media-${post.id}`}
						src={post.thumbnail_url}
						alt={post.caption && post.caption}
					/>
				</div>
			);
		}
		// Image
		if (post.media_type === "IMAGE") {
			return (
				<img
					className={`media-${post.id}`}
					alt={post.caption && post.caption}
					src={post.media_url}
				/>
			);
		}
	};

	videoPlayHandler = () => {
		const { modalOpen } = this.props;
		const videos = this.videoRefs;

		videos &&
			videos.forEach((video) => {
				if (video) {
					const play = (video) => {
						this.setState({
							playing: true,
						});

						video.play();
						video.loop = true;
						video.muted = true;
						//video.classList.add("playing");
					};

					const pause = (video) => {
						this.setState({
							playing: false,
						});

						video.pause();
						video.loop = false;
						video.muted = true;
						//video.classList.remove("playing");
					};

					// Pause when the modal opens, resume when it closes
					!modalOpen && this.isVisible(video) ? play(video) : pause(video);
				}
			});
	};

	isVisible = (video) => {
		const videoRect = video.getBoundingClientRect();
		// Window
		const height = window.innerHeight || document.documentElement.clientHeight;
		const width = window.innerWidth || document.documentElement.clientWidth;
		// Video element
		const left = videoRect.left >= 0;
		const right = videoRect.right <= width;
		const top = videoRect.top + height * 0.25 >= 0; // 75% of the video is visible
		const bottom = videoRect.bottom - height * 0.25 <= height; // 75% of the video is visible
		const all = top && bottom && left && right;
		// Returns a boolean
		return all;
	};

	errorPartial = () => {
		return (
			<div>
				<div className="flex items-center justify-center text-sm font-semibold text-black opaque leading-normal tracking-wide">
					Looks like something went wrong
					<span
						role="img"
						className="text-lg ml-1"
						aria-label="Screaming in Fear emoji"
					>
						😱
					</span>
				</div>
			</div>
		);
	};

	addEvents = () => {
		// Throttling this function improves performance considerably
		const videoPlayHandlerThrottled = throttle(this.videoPlayHandler, 1000, {
			leading: true,
			trailing: true,
		});
		window.addEventListener("scroll", videoPlayHandlerThrottled);
	};

	removeEvents = () => {
		window.removeEventListener("scroll", this.videoPlayHandler);
	};

	componentDidUpdate(prevProps) {
		const { modalOpen } = this.props;

		if (modalOpen !== prevProps.modalOpen) {
			this.videoPlayHandler();
		}
	}

	componentDidMount() {
		this.addEvents();
	}

	componentWillUnmount() {
		this.removeEvents();
	}

	render() {
		return this.showInstagramFeed();
	}
}

export default InstagramFeed;
