import * as types from "../constants/ActionTypes";

export const navBarHidden = actionType => {
  return dispatch => {
    switch (actionType) {
      case types.NAVIGATION_CHANGE_TYPES.HIDDEN:
        dispatch(navigationVisibilityHandler(actionType, true));
        break;

      case types.NAVIGATION_CHANGE_TYPES.VISIBLE:
        dispatch(navigationVisibilityHandler(actionType, false));
        break;

      default:
        return null;
    }
  };
};

export const navBarScrolled = actionType => {
  return dispatch => {
    switch (actionType) {
      case types.NAVIGATION_CHANGE_TYPES.SCROLLED_TO_TARGET:
        dispatch(navigationScrollHandler(actionType, true));
        break;

      case types.NAVIGATION_CHANGE_TYPES.NOT_SCROLLED_TO_TARGET:
        dispatch(navigationScrollHandler(actionType, false));
        break;

      default:
        return null;
    }
  };
};

export const navigationVisibilityHandler = (actionType, payload) => ({
  type: actionType,
  payload
});

export const navigationScrollHandler = (actionType, payload) => ({
  type: actionType,
  payload
});
