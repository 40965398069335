import moment from "moment";

export function isMobile() {
	const w = window,
		d = document,
		e = d.documentElement,
		g = d.getElementsByTagName("body")[0],
		windowWidth = w.innerWidth || e.clientWidth || g.clientWidth; //window width

	return windowWidth <= 823; //returns true for widths smaller than 823 pixels
}

export function parseDate(value) {
	const parsedDate = new Date(1000 * value);
	var monthAndDay = moment(parsedDate).format("MMMM D, YYYY");

	return monthAndDay;
}

export function calculateMediaAspectRatio(
	srcWidth,
	srcHeight,
	maxWidth,
	maxHeight
) {
	const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

	return {
		width: Math.ceil(srcWidth * ratio) + "px",
		height: Math.ceil(srcHeight * ratio) + "px"
	};
}

export function getIndex(value, arr, param) {
	for (var i = 0; i < arr.length; i++) {
		if (arr[i][param] === value) {
			return i;
		}
	}

	// The value doesn't exist
	return -1;
}

export function getCurrentYear() {
	const currentTime = new Date();
	const currentYear = currentTime.getFullYear();

	return currentYear;
}

export function internetExplorer() {
	const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
	const msie = ua.indexOf("MSIE "); // IE 10 or older
	const trident = ua.indexOf("Trident/"); //IE 11

	return msie > 0 || trident > 0;
}
