import React, { Component } from "react";
import { Link } from "react-router-dom";
import { truncate } from "lodash";
import axios from "axios";
import LoadingSpinner from "../../components/LoadingSpinner";

class ProjectThumbnails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			projects: [],
			error: null,
		};
	}

	getProjectData = async () => {
		try {
			return await axios.get("/data/projects.json");
		} catch (error) {
			console.error(error.message);
		}
	};

	getProjects = async () => {
		const response = await this.getProjectData();

		if (response) {
			this.setState({
				loading: false,
				projects: response.data.workProjects,
			});
		} else {
			this.setState({
				error: true,
			});
		}
	};

	showProjects = () => {
		const { loading, projects } = this.state;

		return (
			<div className={`flex flex-wrap -mx-5 mt-8`}>
				{loading ? (
					<LoadingSpinner />
				) : (
					projects.map((project) => (
						<article
							key={project.id}
							className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-5 mb-10"
						>
							<div className="card-container flex flex-col relative h-full bg-white shadow-lg rounded-lg overflow-hidden">
								<Link
									title={project.title}
									className="project-links"
									to={{ pathname: `/${project.parent}/${project.slug}` }}
								>
									<div
										title={project.title}
										className="block bg-cover aspect-16x9"
										style={this.inlineStyle(project)}
									/>
								</Link>
								<div className="flex-1 flex flex-col flex-wrap justify-between p-6">
									<Link
										title={project.title}
										className="project-links no-underline"
										to={{ pathname: `/${project.parent}/${project.slug}` }}
									>
										<h3 className="text-xl text-black font-bold leading-tight mb-4">
											{project.title}
										</h3>
									</Link>
									<Link
										title={project.title}
										className="project-links no-underline"
										to={{ pathname: `/${project.parent}/${project.slug}` }}
									>
										<div className="text-base font-medium text-black opaque leading-normal mb-6">
											{truncate(project.description, {
												length: 85,
												separator: /,?.?-? +/,
											})}
										</div>
									</Link>
									<div className="cta flex items-center justify-center mt-auto pt-4 md:pt-0">
										<Link
											title={project.title}
											className="inline-flex items-center text-lg md:text-base text-black font-semibold no-underline"
											to={{ pathname: `/${project.parent}/${project.slug}` }}
										>
											<span className="underline-indigo">View Project</span>
											<span className="flex pl-2">
												<svg
													className="h-3 w-3"
													viewBox="0 0 20 20"
													fill="currentColor"
												>
													<path
														fill-rule="evenodd"
														d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
														clip-rule="evenodd"
													></path>
												</svg>
											</span>
										</Link>
									</div>
								</div>
							</div>
						</article>
					))
				)}
			</div>
		);
	};

	inlineStyle = (project) => {
		const logo = require(`../../../assets/images/projects/logos/${project.logo}`);
		const style = {
			backgroundImage: `url("${logo}")`,
		};

		return style;
	};

	componentDidMount() {
		this.getProjects();
	}

	render() {
		return <div>{this.showProjects()}</div>;
	}
}

export default ProjectThumbnails;
