export const scrolling = (actionType, payload) => ({
  type: actionType,
  payload
});

// types.SCROLLING_DOWN
export const scrollingDown = (actionType, payload) => ({
  type: actionType,
  payload
});

// types.SCROLLING_UP
export const scrollingUp = (actionType, payload) => ({
  type: actionType,
  payload
});
