import React, { Component } from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

class GoogleAnalytics extends Component {
  componentDidMount() {
    this.logPageChange(
      this.props.location.pathname,
      this.props.location.search
    );
  }

  componentDidUpdate({ location: prevLocation }) {
    const {
      location: { pathname, search }
    } = this.props;
    const isDifferentPathname = pathname !== prevLocation.pathname;
    const isDifferentSearch = search !== prevLocation.search;

    if (isDifferentPathname || isDifferentSearch) {
      this.logPageChange(pathname, search);
    }
  }

  logPageChange(pathname, search = "") {
    const page = pathname + search;
    const { location } = window;
    ReactGA.set({
      page,
      location: `${location.origin}${page}`,
      ...this.props.options
    });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

GoogleAnalytics.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired,
  options: PropTypes.object
};

const RouteTracker = () => {
  return <Route component={GoogleAnalytics} />;
};

const init = (options = {}) => {
  const env = process.env || {};
  const isGAEnabled = !!env.REACT_APP_GA_TRACKING_ID;
  const gaScript = document.querySelector(
    "script[src='https://www.google-analytics.com/analytics.js']"
  );

  if (gaScript) {
    gaScript.remove();
  }

  if (isGAEnabled) {
    ReactGA.initialize(env.REACT_APP_GA_TRACKING_ID, {
      debug: env.REACT_APP_GA_DEBUG === "true",
      ...options
    });
  }

  return isGAEnabled;
};

export default {
  GoogleAnalytics,
  RouteTracker,
  init
};
