import { combineReducers } from "redux";
import navigationReducer from "../reducers/navigationReducer";
import environmentReducer from "../reducers/environmentReducer";
import scrollBehaviorReducer from "../reducers/scrollBehaviorReducer";
import instagramModalReducer from "../reducers/instagramModalReducer";
import instagramDataReducer from "../reducers/instagramDataReducer";
import instagramPageReducer from "../reducers/instagramPageReducer";

const rootReducer = combineReducers({
  environmentReducer,
  scrollBehaviorReducer,
  navigationReducer,
  instagramDataReducer,
  instagramModalReducer,
  instagramPageReducer
});

export default rootReducer;
