import React, { Component } from "react";

class ErrorSkeleton extends Component {
	showErrorSkeleton = () => {
		return (
			<div>
				<div className="flex items-center justify-start text-sm font-semibold text-black opaque leading-normal tracking-wide">
					Looks like something went wrong
					<span
						role="img"
						className="text-lg ml-1"
						aria-label="Screaming in Fear emoji"
					>
						😱
					</span>
				</div>
				<div className="instagram-feed">
					<div className="instagram-feed-container flex flex-wrap -mx-3">
						{/* Row 1 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 2 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 3 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 4 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>

						{/* Row 5 */}
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
						<div className="post w-1/2 md:w-1/5 p-2 sm:p-3">
							<div className="flex bg-grey aspect-1x1 rounded-lg shadow-lg" />
						</div>
					</div>
				</div>
			</div>
		);
	};

	render() {
		return this.showErrorSkeleton();
	}
}

export default ErrorSkeleton;
