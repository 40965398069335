import React, { Component } from "react";

export default class Capabilities extends Component {
  render() {
    return (
      <div>
        <div className="flex flex-wrap justify-start items-center w-full mt-6">
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="HTML5"
              title="HTML5"
              className="max-w-full h-auto invert"
              src={require("../../assets/images/logos/capabilities/html5.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="CSS3"
              title="CSS3"
              className="max-w-full h-auto invert"
              src={require("../../assets/images/logos/capabilities/css3.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Sass"
              title="Sass"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/sass.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Javascript"
              title="Javascript"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/javascript.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="jQuery"
              title="jQuery"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/jquery.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="React.js"
              title="React.js"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/react.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Redux"
              title="Redux"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/redux.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Vue.js"
              title="Vue.js"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/vue.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Socket.io"
              title="Socket.io"
              className="max-w-full h-auto invert"
              src={require("../../assets/images/logos/capabilities/socket-io.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Node.js"
              title="Node.js"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/nodejs.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Laravel"
              title="Laravel"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/laravel.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="WordPress"
              title="WordPress"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/wordpress.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="MySQL"
              title="MySQL"
              className="max-w-full h-auto invert"
              src={require("../../assets/images/logos/capabilities/mysql.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="GitHub"
              title="GitHub"
              className="max-w-full h-auto invert"
              src={require("../../assets/images/logos/capabilities/github.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Adobe Photoshop"
              title="Adobe Photoshop"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/adobe-photoshop.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Adobe Illustrator"
              title="Adobe Illustrator"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/adobe-illustrator.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Adobe Premiere"
              title="Adobe Premiere"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/adobe-premiere.svg")}
            />
          </div>
          <div className="w-1/3 md:w-1/6 py-6 px-10 sm:px-16 md:px-10 lg:px-12 xl:px-16">
            <img
              alt="Adobe After Effects"
              title="Adobe After Effects"
              className="max-w-full h-auto"
              src={require("../../assets/images/logos/capabilities/adobe-after-effects.svg")}
            />
          </div>
        </div>
      </div>
    );
  }
}
