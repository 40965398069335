import * as types from "../constants/ActionTypes";

const initialState = {
	loading: true,
	error: null,
	posts: []
};

export default function instagramDataReducer(state = initialState, action) {
	switch (action.type) {
		case types.GET_INSTAGRAM_DATA_BEGIN:
			return {
				...state,
				loading: true,
				error: null
			};

		case types.GET_INSTAGRAM_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				posts: action.payload.posts
			};

		case types.GET_INSTAGRAM_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload.error,
				posts: []
			};

		default:
			return state;
	}
}
